<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
import { useRouter } from 'vue-router';

import { useField, useForm } from 'vee-validate';
import * as yup from 'yup';

import * as client from '@gabrielcam/api-client';

import { useApplicationStore } from '@stores/application';
import { ButtonType, ButtonVariant, PageNames } from '@viewModels/enums';

import ButtonComponent from '@components/ButtonComponent.vue';
import ContainerCard from '@components/cards/ContainerCard.vue';
import Heading from '@components/Heading.vue';
import SubHeader from '@components/SubHeader.vue';
import ButtonActions from '@layouts/ButtonActions.vue';
import Tooltip from '@components/Tooltip.vue';

interface UserInviteForm {
  displayName: string;
  email: string;
  role: string;
  relId: string;
}
interface ResourceViewModel {
  id: string;
  displayName: string;
}

const schema = yup.object({
  displayName: yup.string().required(),
  email: yup.string().email().required(),
  role: yup.string().required(),
  relId: yup.string().required(),
});

const { handleSubmit } = useForm<UserInviteForm>({
  validationSchema: schema,
});

const { value: displayNameValue, errorMessage: displayNameError } = useField<string>('displayName', 'displayName');
const { value: emailValue, errorMessage: emailError } = useField<string>('email', 'email');
const { value: roleValue, errorMessage: roleError } = useField<string>('role', 'role');
const { value: relIdValue, errorMessage: relIdError } = useField<string>('relId', 'relId');

const router = useRouter();
const applicationStore = useApplicationStore();
const isSubmitting = ref<boolean>(false);
const selectedRole = ref<client.Role>();
const roles = ref<client.Role[]>([]);
const filteredRoles = ref<client.Role[]>([]);

const resources = ref<ResourceViewModel[]>([]);
const filteredResources = ref<ResourceViewModel[]>([]);

onMounted(async () => {
  roles.value = (await client.listRoles()).data.filter((role) => role.userAssignable);
  filteredRoles.value = roles.value;
});
watch(
  () => roleValue.value,
  async () => {
    relIdValue.value = '';
    resources.value = filteredResources.value = [];
    const selectedRole = roles.value.find((x) => x.id == roleValue.value);

    if (!selectedRole) return;

    if (selectedRole.relType == client.Resources.ORGANISATION) {
      resources.value = [
        { id: applicationStore.activeOrganisation!.id, displayName: applicationStore.activeOrganisation!.name },
      ];
      filteredResources.value = resources.value;
    } else if (selectedRole.relType == client.Resources.VIEW) {
      const result = await client.listViews({ organisation: applicationStore.activeOrganisation!.id });
      resources.value = result.data.map((x) => ({ id: x.id, displayName: x.name }));
      filteredResources.value = resources.value;
    } else if (selectedRole.relType == client.Resources.PROJECT) {
      const result = await client.listProjects({ organisation: applicationStore.activeOrganisation!.id });
      resources.value = result.data.map((x) => ({ id: x.id, displayName: x.name }));
      filteredResources.value = resources.value;
    }
    relIdValue.value = resources.value.at(0)?.id ?? '';
  }
);

const inviteUser = async (user: UserInviteForm): Promise<void> => {
  await client.createInvitation({
    requestBody: {
      email: user.email,
      displayName: user.displayName,
      roles: [
        {
          id: user.role,
          relId: user.relId,
          organisation: applicationStore.activeOrganisation!.id,
        },
      ],
    },
  });
};

function roleSelected(role: client.Role): void {
  selectedRole.value = role;
}
const onSubmit = handleSubmit(async (values: any) => {
  isSubmitting.value = true;

  if (!applicationStore.activeOrganisation) return;

  try {
    await inviteUser(values);
    applicationStore.publishSuccessNotification({
      text: 'Successfully invited user.',
      autoCloseMs: 3000,
    });
    router.push({ name: PageNames.Users });
  } catch (error) {
    if (error instanceof client.ApiError) {
      // @ts-ignore
      applicationStore.publishErrorNotification({ text: error.body.error.message });
    } else {
      applicationStore.publishErrorNotification({ text: 'UNKNOWN ERROR' });
    }
  } finally {
    isSubmitting.value = false;
  }
});
</script>

<template>
  <SubHeader heading="Invite User"
             level="2" />

  <ContainerCard>
    <form @submit="onSubmit">
      <div class="field-group">
        <div class="field-group-info">
          <Heading level="3">
            User Information
          </Heading>
          <p>Add the details of the user you wish to invite.</p>
        </div>
        <div class="fields">
          <div class="row-half">
            <div class="field">
              <label for="displayName">Name</label>
              <input id="displayName"
                     v-model="displayNameValue"
                     type="text">
              <p class="message message-error">
                {{ displayNameError }}
              </p>
            </div>
            <div class="field">
              <label for="email">Email</label>
              <input id="email"
                     v-model="emailValue"
                     type="email">
              <p class="message message-error">
                {{ emailError }}
              </p>
            </div>
          </div>
          <div class="row-half">
            <div class="field">
              <label for="Role">
                Role <Tooltip v-if="selectedRole">{{ selectedRole.description }}</Tooltip>
              </label>
              <v-select v-model="roleValue"
                        label="label"
                        :options="filteredRoles"
                        :reduce="(role: client.Role) => role.id"
                        @search="
                          (search: string) => {
                            filteredRoles = roles.filter((x) => {
                              if (!!search.length) return true;
                              return x.shortName.toLowerCase().includes(search.toLowerCase());
                            });
                          }
                        "
                        @option:selected="roleSelected" />
              <p class="message message-error">
                {{ roleError }}
              </p>
            </div>
            <div v-if="selectedRole"
                 class="field">
              <label class="text--capitalize" for="Resource">{{ selectedRole.relType }}</label>
              <v-select v-model="relIdValue"
                        label="displayName"
                        :options="filteredResources"
                        :reduce="(resource: ResourceViewModel) => resource.id"
                        :clearable="false"
                        @search="
                          (search: string) => {
                            filteredResources = resources.filter((x) => {
                              if (!!search.length) return true;
                              return x.displayName.toLowerCase().includes(search.toLowerCase());
                            });
                          }
                        " />
              <p class="message message-error">
                {{ relIdError }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <ButtonActions>
        <ButtonComponent :variant="ButtonVariant.Dark"
                         :disabled="isSubmitting"
                         :type="ButtonType.Submit"
                         :is-block-btn="true">
          Send Invite
        </ButtonComponent>
      </ButtonActions>
    </form>
  </ContainerCard>
</template>
