<script setup lang="ts">
import { onMounted, PropType } from 'vue';

import { ButtonVariant, PageNames } from '@viewModels/enums';
import { IconName, IconStyle } from '@viewModels/heroIcons';

import Loading from '@components/Loading.vue';
import CardContainer from '@layouts/CardContainer.vue';
import EmptyState from '@layouts/EmptyState.vue';

const emit = defineEmits<{
  (e: 'onLoaded'): void;
}>();

const props = defineProps({
  objectAsyncListFunction: {
    type: Function,
    required: false,
    default: null,
  },
  objectCollectionReference: {
    type: Array as PropType<any[]>,
    required: true,
  },
  objectCollectionLimit: {
    type: Number,
    required: false,
    default: 100,
  },
  loading: {
    type: Boolean,
    required: false,
    default: false,
  },
});

// Each list function has a collection limit and pointer
// (collectionLimit: number = 100, pointer: DocumentReference | null = null)
onMounted(async () => {
  // console.log('Directory component mounted');
  if (props.objectAsyncListFunction) {
    // console.log('Calling objectAsyncListFunction');
    props.objectAsyncListFunction();
    emit('onLoaded');
  }
});

// watch(() => props.objectCollectionReference, (newData) => {
//   console.log('Directory received new data:', newData);
// });
</script>

<template>
  <Loading v-if="loading" />

  <CardContainer v-else-if="!loading">
    <template v-for="doc in objectCollectionReference" :key="doc.id">
      <slot :resource="doc" />
    </template>
  </CardContainer>

  <template v-if="objectCollectionReference.length === 0 && !loading">
    <slot name="table-empty">
      <EmptyState heading-text="No videos found"
                  strap-line="Get started by creating a new video"
                  :button-variant="ButtonVariant.Dark"
                  button-text="New Video"
                  :icon-name="IconName.VideoCameraIcon"
                  :icon-style="IconStyle.Outline"
                  :to="{ name: PageNames.VideoNew }" />
    </slot>
  </template>
</template>
