<script setup lang="ts">
import { PropType, reactive } from 'vue';

import Loading from '@components/Loading.vue';

import { TableColumn, TableSetting } from './models/Table';

const { isLoading, data, columns } = defineProps({
  isLoading: {
    type: Boolean,
    required: true,
    default: false,
  },
  data: {
    type: Array as PropType<Array<any>>,
    required: true,
    default: () => {
      return [];
    },
  },
  columns: {
    type: Array as PropType<Array<TableColumn>>,
    default: () => {
      return [];
    },
  },
});

const setting = reactive<TableSetting>({
  page: 1,
  pageSize: 25,
  maxPage: 100,
  offset: 0,
  limit: 1,
  order: '',
  sort: '',
});

const doSort = (order: string): void => {
  let sort = 'asc';
  if (order === setting.order && setting.sort === 'asc') {
    sort = 'desc';
  }
  // let offset = (setting.page - 1) * setting.pageSize;
  // let limit = setting.pageSize;
  setting.order = order;
  setting.sort = sort;
};
// const prevPage = () => {

// }
// const movePage = (page: number) => {

// }
// const nextPage = () => {

// }

function getDescendantProp(row: any, key: string | undefined): string | null {
  if (!key) {
    console.warn('Key should not be undefined');
    return null;
  }

  const value = row[key];
  if (!value) {
    console.warn(`Value should not be undefined, does '${key}' exist?'`);
    return null;
  }
  return value;
}
</script>

<template>
  <Loading v-if="isLoading" />

  <div v-else
       class="table-wrapper">
    <table v-if="data.length != 0"
           :is-loading="!isLoading"
           :data="[]">
      <thead>
        <tr>
          <th v-for="(col, index) in columns as Array<TableColumn>"
              :key="index"
              class=""
              :class="col.headerClasses"
              :style="
                Object.assign(
                  {
                    width: col.width ? col.width : 'auto',
                  },
                  col.headerStyles
                )
              ">
            <div class=""
                 :class="{
                   sortable: col.sortable,
                   asc: setting.order === col.field && setting.sort === 'asc',
                   desc: setting.order === col.field && setting.sort === 'desc',
                 }"
                 @click.prevent="col.sortable ? doSort(col.field) : false">
              {{ col.label }}
            </div>
          </th>
        </tr>
      </thead>
      <template v-if="data.length > 0">
        <tbody>
          <tr v-for="(row, i) in data as Array<any>"
              :key="i">
            <td v-for="(col, j) in columns as Array<TableColumn>"
                :key="j"
                :class="col.headerClasses">
              <slot name="cell"
                    :row="row"
                    :column="col"
                    :value="getDescendantProp(row, col.field)">
                {{ getDescendantProp(row, col.field) }}
              </slot>
            </td>
          </tr>
        </tbody>
      </template>
    </table>

    <div v-if="data.length > 0">
      <!-- paging section -->
    </div>

    <div v-if="data.length === 0"
         class="table-empty">
      <slot name="table-empty">
        No data
      </slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@scss/variables' as *;

.table-wrapper {
  margin-bottom: $margin-bottom;
  overflow-x: auto;
  border: 1px solid $neutral-300;
  border-radius: 10px;

  &:has(.table-empty) {
    border: none;
  }
}

table {
  width: 100%;
  max-width: 100%;
  font-size: 14px;
  border-spacing: 0;
  border-collapse: collapse;
  background: $neutral-100;
  border-radius: 10px;

  thead {
    tr {
      font-weight: 600;

      th {
        padding: 15px 10px;
        text-align: left;
        background: $neutral-200;
        border-right: 1px solid $neutral-300;
        border-bottom: 1px solid $neutral-300;

        &.break-word {
          word-break: break-word;
        }

        &.no-wrap {
          white-space: nowrap;
        }

        &:first-child {
          border-radius: 8px 0 0;
        }

        &:last-child {
          border-right: none;
          border-radius: 0 8px 0 0;
        }
      }
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid $neutral-300;

      &:last-child {
        border-bottom: none;
      }

      td {
        padding: 10px;
        text-align: left;
        background: $neutral-100;
        border-right: 1px solid $neutral-300;

        &:last-child {
          border-right: none;
        }

        &.break-word {
          word-break: break-word;
        }

        &.no-wrap {
          white-space: nowrap;
        }

        &.table-actions {
          height: 0;
          padding: 0;

          ul {
            display: flex;
            height: 100%;
            list-style: none;

            li {
              flex-grow: 1;
              flex-shrink: 0;

              a {
                &:link,
                &:visited {
                  position: relative;
                  display: block;
                  min-width: 36px;
                  height: 100%;
                  padding: 10px;
                  overflow: hidden;
                  font-size: 0.8em;
                  color: $neutral-800;
                  text-align: center;
                  text-decoration: none;
                  text-indent: -3000em;
                  border-right: 1px solid $neutral-300;
                  outline: none;
                  transition: all 0.15s ease;
                }

                &:hover,
                &:focus-visible {
                  color: $neutral-50;
                  background-color: $neutral-800;
                  box-shadow: inset 0 0 0 2px $white-opacity-25;

                  &::before {
                    filter: invert(0);
                  }
                }

                &::before {
                  position: absolute;
                  top: 18%;
                  left: 0;
                  width: 100%;
                  height: 64%;
                  content: '';
                  filter: invert(30%);
                  background-repeat: no-repeat;
                  background-position: center;
                  background-size: 26px;
                  transition: all 0.15s ease;
                }

                &.action-settings::before {
                  background-image: url('/src/assets/icons/settings.png');
                }

                &.action-archive::before {
                  background-image: url('/src/assets/icons/archive.png');
                }

                &.action-invite::before {
                  background-image: url('/src/assets/icons/invite.png');
                }

                &.action-help::before {
                  background-image: url('/src/assets/icons/clients.png');
                }

                &.action-delete::before {
                  background-image: url('/src/assets/icons/cross.png');
                }

                &.action-delete {
                  &:hover,
                  &:focus-visible {
                    background-color: $red-800;
                  }

                  &::before {
                    background-image: url('/src/assets/icons/cross.png');
                  }
                }
              }

              &:last-child {
                a {
                  &:link,
                  &:visited {
                    border-right: 0;
                  }
                }
              }
            }
          }
        }
      }

      &:nth-child(odd) {
        td {
          background: $neutral-50;
        }
      }

      &:hover {
        td {
          background: $neutral-200;
        }
      }
    }

    a {
      &:link,
      &:visited {
        color: $purple-500;
        text-decoration: none;
        outline: none;
      }

      &:hover,
      &:focus,
      &:focus-visible {
        color: $neutral-800;
        text-decoration: underline;
      }
    }
  }
}

div + .table-wrapper {
  margin-top: 30px;
}
</style>
