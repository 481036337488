<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';

import dayjs from 'dayjs';

import * as client from '@gabrielcam/api-client';

import { useApplicationStore } from '@stores/application';
import { AlertVariant, BadgeVariant } from '@viewModels/enums';

import AlertBanner from '@components/AlertBanner.vue';
import BadgeComponent from '@components/BadgeComponent.vue';
import ContainerCard from '@components/cards/ContainerCard.vue';
import Loading from '@components/Loading.vue';

// Route and Camera Information
const route = useRoute();
const cameraId = route.params['id'] as string;
const currentCamera = ref<client.Camera>();

// View Information
const viewId = ref<string | undefined>();
const detailedView = ref<client.View | undefined>();
const noViewFound = ref<boolean>(false);
const applicationStore = useApplicationStore();

// Loading States
const isLoadingViews = ref<boolean>(false);
const isLoadingViewDetails = ref<boolean>(false);
const isLoadingCamera = ref<boolean>(true);

// Fetches the associated view by cameraId
async function getView(): Promise<void> {
  isLoadingViews.value = true;

  // Fetch all views associated with the organisation
  const viewsResult = await client.listViews({ organisation: applicationStore.activeOrganisation!.id });

  // Return early if no views are found
  if (!viewsResult.data || viewsResult.data.length === 0) {
    console.warn('No views found.');
    noViewFound.value = true;
    isLoadingViews.value = false;
    return;
  }

  // Find the view matching the cameraId
  const matchedDetailedView = viewsResult.data.find((view) => view.camera === cameraId);

  if (matchedDetailedView) {
    viewId.value = matchedDetailedView.id;
    detailedView.value = matchedDetailedView;
  } else {
    console.warn('No view found for this camera.');
    noViewFound.value = true;
  }

  isLoadingViews.value = false;
}

onMounted(async () => {
  try {
    currentCamera.value = await client.getCameraById({ cameraId });
    await getView();
  } catch (error) {
    console.error('Error fetching camera or view data:', error);
  } finally {
    isLoadingCamera.value = false;
  }
});

interface Log {
  timestamp: string;
  status: 'info' | 'warning' | 'danger';
  task: string;
}

// Camera Logs
const cameraLogs = ref<Log[]>([
  {
    timestamp: '2024-08-28T10:12:22Z',
    status: 'info',
    task: 'Camera created and initialized',
  },
  {
    timestamp: '2024-08-28T12:04:42Z',
    status: 'warning',
    task: 'Camera lost connection to Balena Cloud',
  },
  {
    timestamp: '2024-08-28T14:45:32Z',
    status: 'danger',
    task: 'Camera encountered a hardware failure',
  },
  {
    timestamp: '2024-08-29T09:15:22Z',
    status: 'info',
    task: 'Settings updated by user',
  },
  {
    timestamp: '2024-08-29T10:30:45Z',
    status: 'info',
    task: 'Camera firmware updated',
  },
]);

function getBadgeVariant(status: Log['status']): BadgeVariant {
  const variantMap: { [key in Log['status']]: BadgeVariant } = {
    info: BadgeVariant.Info,
    warning: BadgeVariant.Warning,
    danger: BadgeVariant.Danger,
  };
  return variantMap[status];
}

function formatTimestamp(timestamp: string): string {
  return dayjs(timestamp).locale('local').format('DD/MM/YYYY HH:mm:ss');
}
</script>

<template>
  <ContainerCard>
    <Loading v-if="isLoadingViews || isLoadingViewDetails || isLoadingCamera" />

    <template v-else>
      <AlertBanner v-if="noViewFound"
                   :variant="AlertVariant.Warning">
        <template #mainContent>
          No view found for {{ currentCamera?.serialNumber }} camera.
        </template>
      </AlertBanner>

      <template v-if="!noViewFound && detailedView">
        <div class="log-grid-container">
          <div class="log-grid">
            <div v-for="(item, index) in cameraLogs"
                 :key="index"
                 class="log-grid__item">
              <span class="log-grid__value">{{ item.task }}</span>
              <div class="log-grid__info">
                <BadgeComponent class="log-grid__timestamp"
                                :variant="BadgeVariant.Light">
                  {{ formatTimestamp(item.timestamp) }}
                </BadgeComponent>
                <BadgeComponent class="log-grid__badge"
                                :is-pill="true"
                                :variant="getBadgeVariant(item.status) as BadgeVariant">
                  {{ item.status }}
                </BadgeComponent>
              </div>
            </div>
          </div>
        </div>
      </template>
    </template>
  </ContainerCard>
</template>

<style lang="scss" scoped>
@use '@scss/variables' as *;

.log-grid {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: clamp($gap-mobile, 3vw, $gap-desktop);
  font-size: 14px;

  &-container {
    container-type: inline-size;
    container-name: log;
  }

  &__item {
    display: flex;
    flex-direction: column;
    gap: 5px 20px;
    padding: 10px 0;
    border-top: 1px solid $neutral-200;

    &:nth-child(1) {
      border-top: 0;
    }
  }

  &__info {
    display: flex;
    gap: 20px;
    justify-content: space-between;
  }

  &__badge {
    min-width: 60px;
    margin-left: auto;
  }

  &__timestamp {
    min-width: 120px;
    white-space: nowrap;
  }

  &__value {
    flex-grow: 1;
    word-break: break-all;
    overflow-wrap: break-word;
  }

  // Using a @container to limit the width because of the sidenav
  @container log (min-width: 800px) {
    padding: 10px 20px;
    border: 1px solid $neutral-400;
    border-radius: 8px;

    &__item {
      flex-direction: row;
    }

    &__info {
      justify-content: flex-start;
    }
  }
}
</style>
