<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import {
  browserSessionPersistence,
  getAuth,
  isSignInWithEmailLink,
  setPersistence,
  signInWithEmailAndPassword,
  signInWithEmailLink,
} from 'firebase/auth';

import * as client from '@gabrielcam/api-client';

import { firebaseApp } from '@utils/firebase';

import { useApplicationStore } from '@stores/application';
import { PageNames } from '@viewModels/enums';

import Loading from '@components/Loading.vue';
import AdditionalServicesCards from '@components/login/AdditionalServicesCards.vue';
import LoginWithEmailAndPasswordForm from '@components/login/LoginWithEmailAndPasswordForm.vue';
import LoginWithEmailLinkForm from '@components/login/LoginWithEmailLinkForm.vue';
import AccountLayout from '@layouts/AccountLayout.vue';

const router = useRouter();
const route = useRoute();

enum SignInMode {
  EmailAndPassword = 1,
  EmailLink = 2,
}

const signInMode = ref(SignInMode.EmailAndPassword);

const applicationStore = useApplicationStore();

const signIn = async (email: string, password?: string): Promise<void> => {
  const authProvider = getAuth(firebaseApp);
  await setPersistence(authProvider, browserSessionPersistence);
  const response = password
    ? await signInWithEmailAndPassword(authProvider, email, password)
    : await signInWithEmailLink(authProvider, email, window.location.href);

  client.OpenAPI.TOKEN = await response.user.getIdToken();
};

onMounted(async () => {
  const authMode = route.query['mode'] && String(route.query['mode']);
  switch (authMode) {
    case 'resetPassword':
      await router.push({ name: PageNames.Forgot, query: route.query });
      break;
    case 'signIn':
      const authProvider = getAuth(firebaseApp);
      if (!isSignInWithEmailLink(authProvider, window.location.href)) {
        break;
      }
      signInMode.value = SignInMode.EmailLink;
      break;
  }
});
</script>

<template>
  <Loading v-if="!applicationStore.applicationReady" />

  <AccountLayout v-else>
    <div class="account-form">
      <template v-if="signInMode === SignInMode.EmailAndPassword">
        <LoginWithEmailAndPasswordForm :sign-in="signIn" />
      </template>
      <template v-else-if="signInMode === SignInMode.EmailLink">
        <LoginWithEmailLinkForm :sign-in="signIn" />
      </template>
    </div>

    <div class="account-link-container">
      <router-link :to="{ name: PageNames.Register }"
                   class="account-link">
        Create an account
      </router-link>
      <span class="account-link-separator">|</span>
      <router-link :to="{ name: PageNames.Forgot }"
                   class="account-link">
        Lost your password
      </router-link>
    </div>

    <!-- TODO: Hide Marketing Articles -->
    <template v-if="false">
      <AdditionalServicesCards v-if="applicationStore.isTimeLapseSystems" />
    </template>
  </AccountLayout>
</template>
