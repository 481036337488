<script lang="ts" setup>
import { reactive, ref } from 'vue';

import * as client from '@gabrielcam/api-client';
import { GetCameraByIdStatLatestData } from '@gabrielcam/api-client';

import { useApplicationStore } from '@stores/application';
import { useViewStore } from '@stores/view';
import {
  BadgeVariant,
  BreadcrumbPaths,
  BreadcrumbTitles,
  ButtonSize,
  ButtonVariant,
  PageNames,
  ViewStatus,
} from '@viewModels/enums';
import { IconName, IconStyle } from '@viewModels/heroIcons';

import BadgeComponent from '@components/BadgeComponent.vue';
import Breadcrumb, { BreadCrumbItem } from '@components/Breadcrumb.vue';
import ButtonComponent from '@components/ButtonComponent.vue';
import ContainerCard from '@components/cards/ContainerCard.vue';
import { TableColumn } from '@components/directory/models/Table';
import TLSTable from '@components/directory/Table.vue';
import Loading from '@components/Loading.vue';
import ModalComponent from '@components/ModalComponent.vue';
import SubHeader from '@components/SubHeader.vue';
import EmptyState from '@layouts/EmptyState.vue';

enum TableHeaders {
  Views = 'All Views',
  Status = 'Status',
  Actions = 'Actions',
}

interface TLSTableType {
  reloadData: () => Promise<void>;
}

// Set View Permissions
const applicationStore = useApplicationStore();
const viewStore = useViewStore();

const showNew = applicationStore.canUser(client.Entitlements.CREATE_CAMERA, applicationStore.activeOrganisation!);
// const showUpdate = applicationStore.canUser(client.Entitlements.UPDATE_CAMERA, applicationStore.activeOrganisation!);
const showDelete = applicationStore.canUser(client.Entitlements.DELETE_CAMERA, applicationStore.activeOrganisation!);
// const showRegister = applicationStore.canUser(client.Entitlements.REGISTER_CAMERA, applicationStore.activeOrganisation!);

const cameraLiveStatus = ref(new Map<string, boolean>());
const isLoading = ref<boolean>(false);
const showDeleteModal = ref<boolean>(false);
const selectedViewToDelete = ref<client.View | null>(null);

const tableRef = ref<TLSTableType | null>(null);

const columns: TableColumn[] = [
  {
    label: TableHeaders.Views,
    field: 'name',
    headerClasses: 'text--white-space-nowrap',
    showActions: false,
    width: '98%',
  },
  {
    label: TableHeaders.Status,
    field: 'status',
    headerClasses: 'status',
    showActions: false,
    width: '1%',
  },
  {
    label: TableHeaders.Actions,
    field: 'actions',
    headerClasses: 'status',
    showActions: false,
    width: '1%',
  },
];

const table = reactive({
  columns: columns,
  sortable: {
    order: 'id',
    sort: 'asc',
  },
});

const breadcrumbs: BreadCrumbItem[] = [
  { title: BreadcrumbTitles.AllViews, to: BreadcrumbPaths.AllViews },
  { title: BreadcrumbTitles.SubscriptionViews, active: true },
];

// Fetch the list of cameras and check live status
async function checkCameraLiveStatus(): Promise<client.View[]> {
  isLoading.value = true;

  try {
    // Fetch the list of views
    const views = await client.listViews({
      organisation: applicationStore.activeOrganisation!.id,
    } as client.ListViewsData);

    // Iterate over the views and check if each camera is live
    await Promise.all(
      views.data.map(async (view: client.View) => {
        try {
          // Check if the camera is live
          await client.getCameraByIdStatLatest({ cameraId: view.camera } as GetCameraByIdStatLatestData);

          // Camera as live in the map
          cameraLiveStatus.value.set(view.camera!, true);
        } catch (e) {
          console.error(`Error fetching camera stats for cameraId: ${view.camera}`, e);
          // Camera as not live in the map
          cameraLiveStatus.value.set(view.camera!, false);
        }
      })
    );

    // Return the views for the table
    return views.data;
  } catch (e) {
    console.error('Error fetching camera list:', e);
    // Return an empty array on error
    return [];
  } finally {
    isLoading.value = false;
  }
}

function showDeleteConfirmModal(row: client.View): void {
  showDeleteModal.value = true;
  selectedViewToDelete.value = row;
}

function closeDeleteConfirmModal(): void {
  showDeleteModal.value = false;
  selectedViewToDelete.value = null;
}

async function deleteView(): Promise<void> {
  if (!selectedViewToDelete.value?.id) return;

  const result = await viewStore.deleteView(selectedViewToDelete.value.id);

  closeDeleteConfirmModal();

  if (!result) {
    applicationStore.publishErrorNotification({ text: 'Failed to delete view.' });
    return;
  }

  applicationStore.publishSuccessNotification({
    text: 'Successfully deleted view.',
    autoCloseMs: 3000,
  });

  if (tableRef.value) {
    await tableRef.value.reloadData();
  }
}
</script>

<template>
  <SubHeader heading="Subscription Views"
             level="2" />

  <Breadcrumb :is-sticky="true"
              :items="breadcrumbs" />

  <ContainerCard>
    <Suspense>
      <template #default>
        <section>
          <TLSTable ref="tableRef"
                    :columns="columns"
                    :retrieve-data="checkCameraLiveStatus"
                    :sortable="table.sortable">
            <template #table-empty>
              <EmptyState heading-text="No views found"
                          :icon-name="IconName.CameraIcon"
                          :icon-style="IconStyle.Outline">
                <template v-if="showNew">
                  <p>Get started by registering your first view</p>
                  <ButtonComponent :to="{ name: PageNames.ViewNew }"
                                   :variant="ButtonVariant.Dark">
                    Add View
                  </ButtonComponent>
                </template>
              </EmptyState>
            </template>

            <template #cell="{ row, column }">
              <template v-if="column.label === TableHeaders.Status">
                <div class="d-flex justify-center">
                  <BadgeComponent v-if="row.status === ViewStatus.Active"
                                  :is-pill="true"
                                  :variant="BadgeVariant.Success">
                    Active
                  </BadgeComponent>
                  <BadgeComponent v-if="row.status === ViewStatus.Inactive"
                                  :is-pill="true"
                                  :variant="BadgeVariant.Danger">
                    Inactive
                  </BadgeComponent>
                </div>
              </template>

              <template v-if="column.label === TableHeaders.Actions">
                <div class="d-flex gap-20">
                  <ButtonComponent v-if="row.id"
                                   :icon-name="IconName.AdjustmentsHorizontalIcon"
                                   :icon-style="IconStyle.Outline"
                                   :to="{ name: PageNames.ViewInformation, params: { id: row.id } }"
                                   :variant="ButtonVariant.Dark"
                                   :size="ButtonSize.Small">
                    Info
                  </ButtonComponent>
                  <ButtonComponent v-if="row.camera && cameraLiveStatus.get(row.camera)"
                                   :icon-name="IconName.CameraIcon"
                                   :icon-style="IconStyle.Outline"
                                   :to="{ name: PageNames.CameraStatus, params: { id: row.camera } }"
                                   :variant="ButtonVariant.Dark"
                                   :size="ButtonSize.Small">
                    Camera
                  </ButtonComponent>
                  <ButtonComponent v-if="showDelete"
                                   :icon-name="IconName.TrashIcon"
                                   :icon-style="IconStyle.Solid"
                                   :variant="ButtonVariant.Danger"
                                   :size="ButtonSize.Small"
                                   @click="showDeleteConfirmModal(row)">
                    Delete
                  </ButtonComponent>
                </div>
              </template>
            </template>
          </TLSTable>
        </section>
      </template>

      <template #fallback>
        <Loading />
      </template>
    </Suspense>
  </ContainerCard>

  <!-- Delete View Modal -->
  <ModalComponent v-if="showDelete && selectedViewToDelete"
                  :visible="true"
                  heading-title="Delete View"
                  @on-close="closeDeleteConfirmModal">
    <template #modal-content>
      <p>
        Are you sure you want to delete the view: <span class="text--bold">{{ selectedViewToDelete.name }}</span>
      </p>
    </template>
    <template #modal-footer>
      <ButtonComponent :is-outline-btn="true"
                       :is-block-btn="true"
                       :variant="ButtonVariant.Dark"
                       @click="closeDeleteConfirmModal">
        Cancel
      </ButtonComponent>
      <ButtonComponent :is-block-btn="true"
                       :variant="ButtonVariant.Danger"
                       @click="deleteView()">
        Delete
      </ButtonComponent>
    </template>
  </ModalComponent>
</template>
