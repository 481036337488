<script setup lang="ts">
import { PropType, ref, toRefs, watch } from 'vue';

import * as client from '@gabrielcam/api-client';

import ViewCardContents from '@components/cards/shared/ViewCardContents.vue';
import ViewCardWrapper from '@components/cards/shared/ViewCardWrapper.vue';

import GalleryCardActions from './GalleryCardActions.vue';

const props = defineProps({
  resource: {
    type: Object as PropType<client.Image>,
    required: true,
  },
  selected: {
    type: Boolean,
    required: false,
  },
});

const thumbnail = ref<string | undefined>();
const overlay = ref<string>('');

defineEmits<{
  (e: 'onDelete', value: client.Image): void;
  (e: 'onClick'): void;
}>();

function update(): void {
  thumbnail.value = `${resource.value.sourceURL}/thumbnail`;
  if (props.resource.originalFileName == undefined) thumbnail.value = undefined;

  if (props.resource.capturedAt !== undefined) {
    overlay.value = `${new Date(props.resource.capturedAt).toLocaleDateString()} ${new Date(
      props.resource.capturedAt
    ).toLocaleTimeString()}`;
  } else {
    overlay.value = '';
  }
}

const { resource } = toRefs(props);
watch(resource, update, { deep: true });
update();
</script>

<template>
  <ViewCardWrapper v-if="resource"
                   :selected="selected">
    <template #card-contents>
      <ViewCardContents :thumbnail="thumbnail"
                        :title="resource.originalFileName!"
                        :overlay="overlay"
                        @on-click="$emit('onClick')" />
    </template>

    <template #card-actions>
      <GalleryCardActions :image-id="resource.id"
                          @delete="$emit('onDelete', resource)" />
    </template>
  </ViewCardWrapper>
</template>

<style lang="scss">
// TODO: Cant pass as scoped just yet - needs refactoring
@use '@/scss/variables' as *;

.collection-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  background: $neutral-100;
  border: 1px solid $neutral-300;
  border-radius: 10px;
  transition: border 0.15s ease;

  &.active {
    color: $neutral-50;
    background: $neutral-700;
    border-color: $neutral-700;

    .collection-item-actions {
      ul {
        border-color: $neutral-700;
      }
    }
  }

  .collection-item-image {
    position: relative;
    height: 0;
    padding-bottom: 56.25%;
    overflow: hidden;
    background: $neutral-300 url('/src/assets/logos/gabrielcam-mark.png') center no-repeat;
    background-size: 25%;

    img {
      position: relative;
      display: block;
      width: 100%;
      height: auto;
      padding-bottom: 56.25%;

      // This hides the horrible looking default broken image icon
      &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 0;
        padding-bottom: 56.25%;
        content: '';
        background: $neutral-300;
      }
    }

    .collection-item-image-link {
      display: block;
      transition: all 0.3s ease;

      &:hover,
      &:focus-visible {
        transform: scale(1.1);
      }
    }

    .collection-item-status {
      position: absolute;
      top: 5px;
      right: 5px;
      width: 25px;
      min-width: 25px;
      height: 25px;
      overflow: hidden;
      text-indent: -300px;
      user-select: none;
      background: $green-800;
      border-radius: 50%;
      box-shadow: inset 0 0 0 2px $white-opacity-25;
      transition:
        text-indent 0.15s ease,
        border-radius 0.15s ease;

      &:hover {
        width: auto;
        height: auto;
        text-indent: 0;
        border-radius: 4px;

        p {
          opacity: 1;
        }
      }

      p {
        padding: 3px 8px;
        margin: 0;
        font-size: 0.8em;
        color: $neutral-50;
        opacity: 0;
      }

      &.status-green {
        background: $green-800;
      }

      &.status-red {
        background: $red-800;
      }

      &.status-orange {
        background: $orange-800;
      }
    }

    .collection-item-overlay {
      position: absolute;
      right: 5px;
      bottom: 5px;
      pointer-events: none;

      p {
        padding: 7px;
        margin: 0;
        font-size: 0.8em;
        line-height: 1;
        color: $neutral-50;
        background: $black-opacity-75;
        border-radius: 5px;
        opacity: 0.9;

        &:empty {
          background: none;
        }
      }
    }

    .collection-item-overlay2 {
      position: absolute;
      top: 5px;
      left: 5px;
      pointer-events: none;

      p {
        padding: 7px;
        margin: 0;
        font-size: 0.8em;
        line-height: 1;
        color: $neutral-50;
        background: $black-opacity-75;
        border-radius: 5px;
        opacity: 0.9;

        &:empty {
          background: none;
        }
      }
    }
  }

  .collection-item-title {
    flex-grow: 1;

    p {
      padding: 10px;
      margin: 0;
      font-weight: 600;

      @media screen and (width >= $breakpoint-lg) {
        padding: 15px;
      }
    }

    a {
      &:link,
      &:visited {
        padding-right: 5px;
        color: $neutral-800;
        text-decoration: none;
        outline: none;
      }

      &:focus-visible {
        border-right: 5px solid $neutral-300;
      }
    }
  }

  .collection-item-actions {
    background-color: $neutral-100;

    ul {
      display: flex;
      justify-content: space-around;
      list-style: none;
      border-top: 1px solid $neutral-300;

      li {
        flex: 1;

        &:first-child > * {
          border-left: none;
        }

        &:last-child > * {
          border-right: none;
        }

        a {
          &:link,
          &:visited {
            position: relative;
            display: block;
            padding: 10px;
            font-size: 0.8em;
            color: $neutral-800;
            text-align: center;
            text-decoration: none;
            text-indent: -3000px;
            background-color: $neutral-100;
            border-right: 1px solid $neutral-300;
            outline: none;
            transition: all 0.15s ease;
          }

          &:hover,
          &:focus-visible {
            background-color: $neutral-700;

            &::before {
              filter: invert(0);
            }
          }

          &::before {
            position: absolute;
            top: 18%;
            left: 0;
            width: 100%;
            height: 64%;
            content: '';
            filter: invert(30%);
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            transition: all 0.15s ease;
          }

          &.action-map::before {
            background-image: url('/src/assets/icons/map.png');
          }

          &.action-image::before {
            background-image: url('/src/assets/icons/views.png');
          }

          &.action-video::before {
            background-image: url('/src/assets/icons/videos.png');
          }

          &.action-camera-status::before {
            background-image: url('/src/assets/icons/cameras.png');
          }

          &.action-archive::before {
            background-image: url('/src/assets/icons/archive.png');
          }

          &.action-invite::before {
            background-image: url('/src/assets/icons/invite.png');
          }

          &.action-delete::before {
            background-image: url('/src/assets/icons/cross.png');
          }

          &.action-delete {
            &:hover,
            &:focus-visible {
              background-color: $red-800;
            }

            &::before {
              background-image: url('/src/assets/icons/cross.png');
            }
          }
        }

        button {
          position: relative;
          display: block;
          width: 100%;
          padding: 10px;
          font-size: 0.8em;
          color: $neutral-800;
          text-align: center;
          text-decoration: none;
          text-indent: -3000px;
          background-color: $neutral-100;
          border: none;
          border-right: 1px solid $neutral-300;
          outline: none;
          transition: all 0.15s ease;

          &:hover,
          &:focus-visible {
            background-color: $neutral-700;

            &::before {
              filter: invert(0);
            }
          }

          &::before {
            position: absolute;
            top: 18%;
            left: 0;
            width: 100%;
            height: 64%;
            content: '';
            filter: invert(30%);
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            transition: all 0.15s ease;
          }

          &.action-play::before {
            background-image: url('/src/assets/icons/play.png');
          }

          &.action-download::before {
            background-image: url('/src/assets/icons/download.png');
          }

          &.action-delete {
            &:hover,
            &:focus-visible {
              background-color: $red-800;
            }

            &::before {
              background-image: url('/src/assets/icons/cross.png');
            }
          }
        }

        &:first-child {
          a {
            &:link,
            &:visited {
              border-bottom-left-radius: 7px;
            }
          }
        }

        &:last-child {
          a {
            &:link,
            &:visited {
              border-right: 0;
              border-bottom-right-radius: 7px;
            }
          }
        }
      }
    }
  }
}
</style>
