<script setup lang="ts">
import { ref } from 'vue';
import { useRouter } from 'vue-router';

import { useField, useForm } from 'vee-validate';
import * as yup from 'yup';

import * as client from '@gabrielcam/api-client';

import { useApplicationStore } from '@stores/application';
import { BreadcrumbPaths, BreadcrumbTitles, ButtonType, ButtonVariant, PageNames } from '@viewModels/enums';

import Breadcrumb, { BreadCrumbItem } from '@components/Breadcrumb.vue';
import ButtonComponent from '@components/ButtonComponent.vue';
import ContainerCard from '@components/cards/ContainerCard.vue';
import SubHeader from '@components/SubHeader.vue';
import ButtonActions from '@layouts/ButtonActions.vue';
import Heading from '@components/Heading.vue';

interface ClientCreationForm {
  name: string;
  logo: string;
  theme: string;
}

const schema = yup.object({
  name: yup.string().required(),
});

const { handleSubmit } = useForm<ClientCreationForm>({
  validationSchema: schema,
});

const { value: nameValue, errorMessage: nameError } = useField<string>('name', 'name');
const { value: logoValue, errorMessage: logoError } = useField<string>('logo', 'logo');
const { value: themeValue, errorMessage: themeError } = useField<string>('theme', 'theme');

const router = useRouter();
const applicationStore = useApplicationStore();
const isSubmitting = ref<boolean>(false);

const onSubmit = handleSubmit(async (values) => {
  isSubmitting.value = true;
  if (!applicationStore.activeOrganisation) return;

  try {
    await client.createClient({
      requestBody: {
        name: values.name,
        organisation: applicationStore.activeOrganisation.id,
      },
    });
    applicationStore.publishSuccessNotification({
      text: 'Successfully created client.',
      autoCloseMs: 3000,
    });
  } catch (error) {
    if (error instanceof client.ApiError) {
      // @ts-ignore
      applicationStore.publishErrorNotification({ text: error.body.error.message });
    }
    applicationStore.publishErrorNotification({ text: 'UNKNOWN ERROR' });
    isSubmitting.value = false;
    return;
  }
  router.push({ name: PageNames.Clients });
});

// Handle navigation
const cancelBtn = (): void => {
  const routerHistory = router.options.history;
  const backUrl = routerHistory.state['back'];

  // If previous route is login, navigate to /clients
  if (typeof backUrl === 'string' && backUrl.startsWith('/login?continueUrl=')) {
    router.push(BreadcrumbPaths.Clients as string);
  } else if (routerHistory.state['back']) {
    // If there's a valid previous route, go back
    router.go(-1);
  } else {
    router.push(BreadcrumbPaths.AllViews as string);
  }
};

const breadcrumbs: BreadCrumbItem[] = [
  { title: BreadcrumbTitles.AllViews, to: BreadcrumbPaths.AllViews },
  { title: BreadcrumbTitles.Clients, to: BreadcrumbPaths.Clients },
  { title: BreadcrumbTitles.CreateClient, active: true },
];
</script>

<template>
  <SubHeader heading="Create Client"
             level="2" />

  <Breadcrumb :is-sticky="true"
              :items="breadcrumbs" />

  <ContainerCard>
    <form @submit="onSubmit">
      <div class="field-group">
        <div class="field-group-info">
          <Heading level="3">
            Client Information
          </Heading>
          <p>Add a new client and customise their experience with their logo and a theme.</p>
        </div>
        <div class="fields">
          <div class="field">
            <label for="client-name">Name</label>
            <input id="client-name"
                   v-model="nameValue"
                   type="text">
            <p class="message message-error">
              {{ nameError }}
            </p>
          </div>
          <div class="field">
            <label for="client-logo">Logo</label>
            <input id="client-logo"
                   v-model="logoValue"
                   type="text">
            <p class="message message-error">
              {{ logoError }}
            </p>
          </div>
          <div class="field">
            <label for="client-theme">Theme</label>
            <input id="client-theme"
                   v-model="themeValue"
                   type="text">
            <p class="message message-error">
              {{ themeError }}
            </p>
          </div>
        </div>
      </div>

      <ButtonActions>
        <ButtonComponent :is-block-btn="true"
                         :variant="ButtonVariant.Dark"
                         :is-outline-btn="true"
                         @click="cancelBtn">
          Cancel
        </ButtonComponent>
        <ButtonComponent :is-block-btn="true"
                         :type="ButtonType.Submit"
                         :variant="ButtonVariant.Dark"
                         :disabled="isSubmitting">
          Create
        </ButtonComponent>
      </ButtonActions>
    </form>
  </ContainerCard>
</template>
