import './scss/style.scss';
import { createApp } from 'vue';
import VueDOMPurifyHTML from 'vue-dompurify-html';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

import * as Sentry from '@sentry/vue';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { getAuth, onIdTokenChanged } from 'firebase/auth';
import FloatingVue, { vTooltip } from 'floating-vue';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import { setupCalendar } from 'v-calendar';

import * as client from '@gabrielcam/api-client';

import router from '@router/index';

import { useApplicationStore } from '@stores/application';

import Notification from '@components/Notification.vue';

import App from './App.vue';

dayjs.extend(utc);
client.OpenAPI.BASE = '/api';
let refreshTokenInterval: NodeJS.Timeout | undefined;

const app = createApp(App);

Sentry.init({
  enabled: import.meta.env.PROD,
  app,
  dsn: 'https://0eb44554250545ddb6d05b2e2cef1cae@o297538.ingest.sentry.io/4504560416260096',
  integrations: [Sentry.browserTracingIntegration({ router })],
  tracesSampleRate: 1.0,
  tracePropagationTargets: [
    'localhost',
    /^https:\/\/hub.uat.gabrielcam\.com\/api/,
    /^https:\/\/hub.gabrielcam\.com\/api/,
  ],
});

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
app.use(pinia);
app.use(router);
app.use(setupCalendar, {});

onIdTokenChanged(getAuth(), async (user) => {
  client.OpenAPI.TOKEN = !!user ? await user.getIdToken() : undefined;
  const applicationStore = useApplicationStore();
  await applicationStore.setActiveUser();

  if (!refreshTokenInterval && user !== null) {
    // refresh token every 55 minutes
    refreshTokenInterval = setInterval(async () => await user.getIdToken(true), 55 * 60 * 1000);
  }
  applicationStore.applicationReady = true;
});

// Define the tooltip settings
// https://floating-vue.starpad.dev/guide/config
const floatingVueConfig = {
  boundary: 'window',
  instantMove: false,
  strategy: 'absolute',
  preventOverflow: true,
  themes: {
    tooltip: {
      placement: 'top',
      triggers: ['focus', 'click', 'touch'],
      delay: {
        show: 100,
        hide: 0,
      },
      handleResize: false,
      html: false,
      loadingContent: '...',
    },
    dropdown: {
      placement: 'bottom',
      triggers: ['click'],
      delay: 100,
      handleResize: false,
      autoHide: true,
    },
  },
};
app.use(VueDOMPurifyHTML);
app.use(FloatingVue, floatingVueConfig);
app.component('VSelect', vSelect);
app.component('VNotification', Notification);
app.mount('#app');
