<script setup lang="ts">
import { useRouter } from 'vue-router';
import * as client from '@gabrielcam/api-client';
import * as yup from 'yup';
import { ErrorMessage, useForm, useField } from 'vee-validate';
import timezones, { TimeZone } from 'timezones-list';

import { useApplicationStore } from '@stores/application';
import { ButtonType, ButtonVariant, PageNames } from '@viewModels/enums';

import AccountLayout from '@layouts/AccountLayout.vue';
import ButtonComponent from '@components/ButtonComponent.vue';

// Extended the RegisterOrganisationRequest to include confirmPassword
interface RegisterFormValues extends client.RegisterOrganisationRequest {
  confirmPassword: string;
}

const router = useRouter();
const applicationStore = useApplicationStore();

// Form Validation
const validationSchema = yup.object({
  name: yup
    .string()
    .min(3, 'Organisation name must be at least 3 characters')
    .max(200, 'Organisation name must be less than 200 characters')
    .required('Organisation name is required'),

  forename: yup
    .string()
    .min(2, 'First name must be at least 2 characters')
    .max(200, 'First name must be less than 200 characters')
    .required('First name is required'),

  surname: yup
    .string()
    .min(2, 'Last name must be at least 2 characters')
    .max(200, 'Last name must be less than 200 characters')
    .required('Last name is required'),

  email: yup
    .string()
    .email('Invalid email address')
    .required('Email is required'),

  password: yup
    .string()
    .min(5, 'Password must be at least 5 characters')
    .required('Password is required'),

  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password')], 'Passwords must match')
    .required('Confirm password is required'),

  timezone: yup.string().optional(),
});


// Wrap useForm to handle the form and attach validation schema
const { handleSubmit, setFieldError } = useForm<RegisterFormValues>({ validationSchema });

// Attach fields to form
const { value: organisationNameValue } = useField('name', undefined, { validateOnValueUpdate: false });
const { value: forenameValue } = useField('forename', undefined, { validateOnValueUpdate: false });
const { value: surnameValue } = useField('surname', undefined, { validateOnValueUpdate: false });
const { value: emailValue } = useField('email', undefined, { validateOnValueUpdate: false });
const { value: passwordValue } = useField('password', undefined, { validateOnValueUpdate: false });
const { value: confirmPasswordValue } = useField('confirmPassword', undefined, { validateOnValueUpdate: false });
const { value: timezoneValue } = useField('timezone', undefined, { validateOnValueUpdate: false });

/**
 * Handles the form submission for user registration.
 *
 * @param {RegisterFormValues} values - The form values submitted by the user.
 */
const onSubmit = handleSubmit(async (values: RegisterFormValues) => {
  try {
    // Remove confirmPassword from request body as we don't need it
    const { confirmPassword: _confirmPassword, ...requestBody } = values;

    // If timezone is left empty, remove it from the request
    if (!requestBody.timezone) {
      delete requestBody.timezone;
    }

    await client.registerOrganisation({
      requestBody: requestBody as client.RegisterOrganisationRequest,
    });

    applicationStore.publishSuccessNotification({
      text: 'Successfully registered organisation.',
      autoCloseMs: 3000,
    });

    // Redirect to login page
    router.push({ name: PageNames.Login });

  } catch (error: unknown) { // Use unknown
    console.error("Error during registration:", error);

    // Type narrowing to check if it's an ApiError
    if (error instanceof client.ApiError) {
      console.error("ApiError details:", error);

      const errorBody = error.body as {
        error: {
          code: string;
          errors: string[];
        };
      };

      // Handle validation errors from the API
      if (errorBody?.error?.code === 'validation_error') {
        const validationErrors = errorBody.error.errors;

        // Map errors to form fields
        validationErrors.forEach((validationError: string) => {
          if (validationError.includes('email')) {
            setFieldError('email', 'This email already exists, please choose another one.');
          }
          if (validationError.includes('organisation')) {
            setFieldError('name', 'This organisation name already exists, please choose another one.');
          }
        });
      } else {
        applicationStore.publishErrorNotification({
          text: 'Unexpected error occurred when registering organisation.',
        });
      }
    } else {
      // Fallback for other unexpected errors
      applicationStore.publishErrorNotification({
        text: 'Unexpected error occurred when registering organisation.',
      });
    }
  }
});
</script>

<template>
  <AccountLayout>
    <div class="account-form">
      <form @submit.prevent="onSubmit">
        <section class="account-form--content">
          <div class="fields">
            <div class="field">
              <label for="organisationName">Organisation Name</label>
              <input id="organisationName"
                     v-model="organisationNameValue"
                     type="text"
                     autocomplete="organization">
              <ErrorMessage name="name" class="message message-error" as="p" />
            </div>
          </div>

          <div class="fields">
            <div class="field">
              <label for="forename">First Name</label>
              <input id="forename"
                     v-model="forenameValue"
                     type="text"
                     autocomplete="given-name">
              <ErrorMessage name="forename" class="message message-error" as="p" />
            </div>
          </div>

          <div class="fields">
            <div class="field">
              <label for="surname">Last Name</label>
              <input id="surname"
                     v-model="surnameValue"
                     type="text"
                     autocomplete="family-name">
              <ErrorMessage name="surname" class="message message-error" as="p" />
            </div>
          </div>

          <div class="fields">
            <div class="field">
              <label for="timezone">Timezone (optional)</label>
              <v-select id="timezone"
                        v-model="timezoneValue"
                        :reduce="(timezone: TimeZone) => timezone.tzCode"
                        :options="timezones"
                        autocomplete="off" />
              <ErrorMessage name="timezone" class="message-error message" as="p" />
            </div>
          </div>

          <div class="fields">
            <div class="field">
              <label for="email-address">Email address</label>
              <input id="email-address"
                     v-model="emailValue"
                     type="email"
                     autocomplete="email">
              <ErrorMessage name="email" class="message message-error" as="p" />
            </div>
          </div>

          <div class="fields">
            <div class="field">
              <label for="password">Password</label>
              <input id="password"
                     v-model="passwordValue"
                     type="password"
                     autocomplete="new-password">
              <ErrorMessage name="password" class="message message-error" as="p" />
            </div>
          </div>

          <div class="fields">
            <div class="field">
              <label for="password-confirm">Confirm password</label>
              <input id="password-confirm"
                     v-model="confirmPasswordValue"
                     type="password"
                     autocomplete="new-password">
              <ErrorMessage name="password-confirm" class="message message-error" as="p" />
            </div>
          </div>
        </section>

        <section class="account-footer">
          <ButtonComponent :type="ButtonType.Submit"
                           :variant="ButtonVariant.Dark"
                           :is-block-btn="true">
            Create Account
          </ButtonComponent>
        </section>
      </form>
    </div>

    <div class="account-link-container">
      <span>Already have an account? 
        <router-link :to="{ name: PageNames.Login }"
                     class="account-link">
          Log in
        </router-link>
      </span>
    </div>
  </AccountLayout>
</template>
