<script setup lang="ts">
import { BadgeVariant, ButtonVariant, PageNames } from '@viewModels/enums';
import BadgeComponent from '@components/BadgeComponent.vue';
import { computed, onMounted, ref, watch } from 'vue';
import * as client from '@gabrielcam/api-client';
import Heading from '@components/Heading.vue';
import { dateTimeFormat } from '@utils/date';
import { useApplicationStore } from '@stores/application';
import ButtonComponent from '@components/ButtonComponent.vue';
import { IconName, IconStyle } from '@viewModels/heroIcons';
import { Entitlements, GetCameraByIdStatLatestData } from '@gabrielcam/api-client';

// Card interface
interface ViewCardNewProps {
  resource: client.View;
  selected?: boolean;
}

// Default Props
const props = withDefaults(defineProps<ViewCardNewProps>(), {
  selected: false,
});

// Refs
const scrollToSelectedCard = ref<HTMLDivElement>();

// Stores
const applicationStore = useApplicationStore();
const { activeUser } = applicationStore;
const isViewCameraLive = ref<boolean>(false);

// Loading States
const isCameraLoading = ref<boolean>(true);
// Pass multiple loading states to isLoading to allow for lazy loading
const isLoading = computed(() => isCameraLoading.value);

// Permissions
const hasViewCameraAccess = applicationStore.canUser(Entitlements.UPDATE_VIEW, applicationStore.activeOrganisation!);

// Prop References
const viewStatus = ref<client.ViewStatus>(props.resource.status || client.ViewStatus.INACTIVE);
const viewName = ref(props.resource.name);
const viewClientName = computed(() => props.resource.includes?.client?.name ?? 'No client');
const viewProjectName = computed(() => props.resource.includes?.project?.name ?? 'No project');
const viewImageUrl = computed(() => props.resource.latestImageURL);
// Does the view have an image
const viewHasImage = computed(() => !!viewImageUrl.value);

const viewImageLastCapture = computed(() =>
  props.resource.lastCapturedUtc
    ? dateTimeFormat(activeUser?.timezone).format(new Date(props.resource.lastCapturedUtc))
    : 'Never',
);
const viewCameraId = computed(() => props.resource.camera);
const viewSelected = computed(() => props.selected);

const fallbackMessage = computed(() => {
  const fallbackMessages = {
    [client.ViewStatus.INACTIVE]: 'This view is inactive and may not have any images captured',
    [client.ViewStatus.ARCHIVE]: 'This view is archived',
    [client.ViewStatus.ACTIVE]: 'This view is active',
  };
  return fallbackMessages[viewStatus.value] || '';
});

// Badge variant mapping
const badgeVariantMapping = {
  [client.ViewStatus.ACTIVE]: BadgeVariant.Success,
  [client.ViewStatus.INACTIVE]: BadgeVariant.Danger,
  [client.ViewStatus.ARCHIVE]: BadgeVariant.Warning,
};

// Compute Badge Variants
const badgeVariant = computed(() => badgeVariantMapping[viewStatus.value] || BadgeVariant.Danger);

/**
 * Checks the live status of a camera.
 *
 * This function fetches the latest camera stats if they exist and updates the `isViewCameraLive` ref accordingly.
 *
 */
async function checkCameraIsLive(): Promise<void> {
  isCameraLoading.value = true;

  if (!viewCameraId.value) {
    isCameraLoading.value = false;
    return;
  }

  try {
    const cameraStats = await client.getCameraByIdStatLatest({ cameraId: viewCameraId.value } as GetCameraByIdStatLatestData);
    isViewCameraLive.value = !!cameraStats;
  } catch (error: unknown) {
    if (error instanceof client.ApiError && error.status === 404) {
      console.warn(`Camera does not exist: ${viewCameraId.value}`);
      isViewCameraLive.value = false;
    } else if (error instanceof client.ApiError) {
      console.error(`ApiError: ${error.status} - ${error.statusText}`);
    } else {
      console.error("Unexpected error during camera status check:", error);
    }
  } finally {
    isCameraLoading.value = false;
  }
}

onMounted(() => {
  checkCameraIsLive();
  scrollToCard();
});

watch(
  () => viewSelected.value,
  () => {
    scrollToCard();
  }
);

/**
 * Scroll to the element referenced by the ref `scrollToSelectedCard` if
 * `viewSelected` is true - this is used in the Map View Page to scroll to the selected card.
 */
function scrollToCard(): void {
  if (!viewSelected.value || !scrollToSelectedCard.value) return;

  scrollToSelectedCard.value.scrollIntoView({
    behavior: 'smooth',
    block: 'center',
  });
}
</script>

<template>
  <div ref="scrollToSelectedCard" class="view-card-new__container" :class="{ 'view-card-new__container--selected': viewSelected }">
    <!-- Show The Skeleton Card -->
    <div v-show="isLoading" class="view-card-new">
      <div class="view-card-new__status">
        <div class="skeleton-card skeleton-card__badge" />
      </div>

      <div class="view-card-new__header">
        <div class="skeleton-card skeleton-card__title" />
        <div class="skeleton-card skeleton-card__detail" />
        <div class="skeleton-card skeleton-card__detail" />
      </div>

      <div class="view-card-new__image">
        <div class="skeleton-card skeleton-card__image" />
      </div>

      <div class="view-card-new__details">
        <div class="skeleton-card skeleton-card__image-detail" />
      </div>

      <div class="view-card-new__actions">
        <div class="skeleton-card skeleton-card__button" />
        <div class="skeleton-card skeleton-card__button" />
        <div class="skeleton-card skeleton-card__button" />
        <div class="skeleton-card skeleton-card__button" />
      </div>
    </div>

    <!-- Show The Real Card When Loaded -->
    <div v-show="!isLoading"
         :class="['view-card-new', {'view-card-new--selected': viewSelected }, `view-card-new--${viewStatus.toLowerCase()}`]">
      <div v-if="viewStatus" class="view-card-new__status">
        <BadgeComponent :variant="badgeVariant">
          {{ viewStatus }} VIEW
        </BadgeComponent>
      </div>

      <div v-if="viewName" class="view-card-new__header">
        <Heading level="4" class="view-card-new__header--title">
          {{ viewName }}
        </Heading>
        <div class="view-card-new__header--details">
          <div class="text--truncate">
            <span class="text--bold">Project:</span> {{ viewProjectName }}
          </div>
          <div class="text--truncate">
            <span class="text--bold">Client:</span> {{ viewClientName }}
          </div>
        </div>
      </div>

      <div class="view-card-new__image">
        <!-- Show a fallback image/logo if viewImageUrl fails -->
        <template v-if="viewImageUrl">
          <router-link :to="{ name: PageNames.ViewViewer, params: { id: props.resource.id } }">
            <img :src="`${viewImageUrl}/card`"
                 aria-hidden="true"
                 :class="`view-card-new__image--${viewStatus.toLowerCase()}`"
                 :alt="`${viewName} view`">
          </router-link>
        </template>
        <template v-else>
          <p class="view-card-new__image--fallback">
            {{ fallbackMessage }}
          </p>
        </template>
      </div>

      <div class="view-card-new__content">
        <div class="text--truncate">
          <span class="text--bold">Last captured:</span> {{ viewImageLastCapture }}
        </div>
      </div>

      <div class="view-card-new__actions">
        <ButtonComponent :disabled="!viewHasImage"
                         :variant="ButtonVariant.Primary"
                         :is-icon-btn="true"
                         :icon-name="IconName.MapPinIcon"
                         :icon-style="IconStyle.Outline"
                         aria-label="View on map"
                         :to="viewHasImage ? { name: PageNames.ViewMap, params: { viewId: props.resource.id } } : ''" />

        <ButtonComponent :disabled="!viewHasImage"
                         :variant="ButtonVariant.Primary"
                         :is-icon-btn="true"
                         :icon-name="IconName.ViewfinderCircleIcon"
                         :icon-style="IconStyle.Outline"
                         aria-label="Viewer"
                         :to="viewHasImage ? { name: PageNames.ViewViewer, params: { id: props.resource.id } } : ''" />
        <ButtonComponent :disabled="!viewHasImage"
                         :variant="ButtonVariant.Primary"
                         :is-icon-btn="true"
                         :icon-name="IconName.PhotoIcon"
                         :icon-style="IconStyle.Outline"
                         aria-label="Gallery"
                         :to="viewHasImage ? { name: PageNames.ViewGallery, params: { id: props.resource.id } } : ''" />
        <ButtonComponent :disabled="!viewHasImage"
                         :variant="ButtonVariant.Primary"
                         :is-icon-btn="true"
                         :icon-name="IconName.VideoCameraIcon"
                         :icon-style="IconStyle.Outline"
                         aria-label="Videos"
                         :to="viewHasImage ? { name: PageNames.ViewVideos, params: { id: props.resource.id } } : ''" />
        <!-- Only show Camera Status Button if a camera is live and if the user has permission to access -->
        <ButtonComponent v-if="hasViewCameraAccess && isViewCameraLive"
                         :variant="ButtonVariant.Primary"
                         :is-icon-btn="true"
                         :icon-name="IconName.CameraIcon"
                         :icon-style="IconStyle.Outline"
                         :to="{ name: PageNames.CameraStatus, params: { id: viewCameraId } }" />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@use '@/scss/variables' as *;

.view-card-new {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  padding: $gap-mobile;
  background-color: $neutral-100;
  border-radius: 10px;
  box-shadow: inset 0 0 0 1px $neutral-300;
  transition: background-color 300ms ease, box-shadow 300ms ease;

  &--inactive {
    background-color: $red-100;
    box-shadow: inset 0 0 0 1px $red-300;
  }

  &--archive {
    background-color: $neutral-400;
    box-shadow: inset 0 0 0 1px $orange-800;
    opacity: 0.8;
  }

  &--selected {
    background-color: $green-200;
    box-shadow: inset 0 0 0 1px $green-800;
  }

  &__status {
    align-self: flex-end;
  }

  &__header {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    padding-block: 5px;
    margin-bottom: $margin-bottom;

    &--title {
      display: -webkit-box;
      overflow: hidden;
      line-height: 1.2;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;

      @media screen and (min-width: $breakpoint-sm) {
        flex-basis: 2.4em;
      }
    }

    &--details {
      display: flex;
      flex-direction: column;
      font-size: .875rem;
      color: $neutral-600;
    }
  }

  &__image {
    display: flex;
    align-items: center;
    justify-content: center;

    // Use aspect ratio to stop CLS (Content Layout Shift)
    aspect-ratio: 16 / 9;
    margin-bottom: 5px;
    overflow: hidden;
    background: $neutral-200 url('/src/assets/background-light.png') repeat center center;
    background-size: 25%;
    border-radius: 10px;
    box-shadow: 0 0 0 1px $neutral-300;

    & img {
      max-width: 100%;
      height: auto;
      overflow: hidden;
      object-fit: cover;
      border-radius: 10px;
      transition: transform 200ms ease-in;
    }

    &--active {
      &:hover {
        cursor: pointer;
        transform: scale(1.05);
      }
    }

    &--archive {
      cursor: pointer;
      filter: grayscale(100%);
    }

    &--inactive {
      cursor: pointer;
      filter: grayscale(100%);
    }

    &--fallback {
      padding-inline: $gap-desktop;
      font-size: .875rem;
      color: $neutral-600;
      text-align: center;
    }
  }

  &__content {
    margin-bottom: $margin-bottom;
    font-size: .875rem;
    color: $neutral-600;
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

/* Skeleton Loading Effect */
.skeleton-card {
  --initial-color: #f0f0f0;
  --final-color: #e0e0e0;

  background: linear-gradient(90deg, var(--initial-color), var(--final-color), var(--initial-color));
  background-size: 200% 100%;
  border-radius: 4px;
  animation: pulse 3000ms ease-in-out infinite;

  &__badge {
    width: 80px;
    height: 24px;
  }

  &__title {
    width: 200px;
    height: 24px;
  }

  &__detail {
    width: 100%;
    height: 16px;

    &:last-of-type {
      margin-bottom: 10px;
    }
  }

  &__image {
    width: 100%;
    aspect-ratio: 16 / 9;
  }

  &__image-detail {
    width: 100%;
    height: 16px;
    margin-bottom: 20px;
  }

  &__button {
    display: inline-block;
    width: 40px;
    height: 40px;
    margin-right: 8px;
  }
}
</style>
