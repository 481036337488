<script setup lang="ts">
import { useRoute } from 'vue-router';
import EditUserForm from '@components/account_forms/EditUserForm.vue';
const route = useRoute();
const userId: string = route.params['id'] as string;
</script>

<template>
  <EditUserForm :user-id="userId" />
</template>
