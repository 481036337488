<script setup lang="ts">
import { storeToRefs } from 'pinia';

import { useApplicationStore } from '@stores/application';

import { IconName, IconStyle } from '@viewModels/heroIcons';
import { ButtonVariant } from '@viewModels/enums';
import ButtonComponent from '@components/ButtonComponent.vue';

const applicationStore = useApplicationStore();
const { currentNotification } = storeToRefs(applicationStore);

function closeNotification(): void {
  currentNotification.value = undefined;
}
</script>

<template>
  <div class="notification"
       :class="currentNotification?.class">
    <div class="notification-content">
      <p>{{ currentNotification?.text }}</p>
      <ButtonComponent aria-label="Close notification"
                       :icon-name="IconName.XCircleIcon"
                       :icon-style="IconStyle.Outline"
                       :is-outline-btn="true"
                       class="notification-close"
                       :variant="ButtonVariant.Link"
                       :is-icon-btn="true"
                       @click="closeNotification" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@scss/variables' as *;

.notification {
  position: fixed;
  right: -100%;
  bottom: 85px;
  z-index: 9999;
  width: calc(100% - $gap-desktop);
  padding: clamp($gap-mobile, 2vw, $gap-desktop);
  color: $neutral-50;
  background: $neutral-800;
  border-radius: 10px;
  box-shadow: $box-shadow;
  opacity: 0.9;
  transition: right 0.15s linear;
  animation: floating 1s alternate linear infinite;

  @media screen and (width >= $breakpoint-lg) {
    bottom: $gap-default;
    max-width: 450px;
  }

  @media (prefers-reduced-motion) {
    animation: none;
  }

  &.open {
    right: 15px;
  }

  &:hover {
    opacity: 1;
    animation-play-state: paused;
  }

  &.notification-success {
    color: $neutral-50;
    background: $green-800;
    border-color: $green-600;
  }

  &.notification-error {
    color: $neutral-50;
    background: $red-800;
    border-color: $red-400;
  }

  .notification-content {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 10px;
    align-items: center;

    p {
      margin: 0;
    }

    .notification-close {
      color: inherit;
      opacity: 0.9;
      transition: all 0.15s ease;

      &:hover,
      &:focus-visible {
        opacity: 1;
      }
    }
  }
}
</style>

