<script setup lang="ts">
import { computed, ref, watch } from 'vue';

import dayjs from 'dayjs';
import { DatePicker } from 'v-calendar';
import 'v-calendar/style.css';
import { DatePickerTheme } from '@viewModels/enums';

import { generateMissingDateRanges } from '@utils/generateMissingDates';

interface DatePickerProps {
  availableDates: string[];
  modelValue: string;
  selectedDate: string;
  variant?: DatePickerTheme;
}

const props = defineProps<DatePickerProps>();

const date = ref(props.selectedDate);
const height = ref(601);
const calendar = ref<InstanceType<typeof DatePicker>>();

const calendarStyles = computed(() => {
  if (props.variant === DatePickerTheme.Dark) {
    return {
      '--vc-bg': 'var(--primary-color, transparent)',
      '--vc-color': 'var(--vc-gray-300)',
      '--vc-text-lg': '16px',
      '--vc-day-content-disabled-color': 'var(--vc-gray-600)',
      '--vc-weekday-color': '#FAFAFA',
      '--vc-rounded-full': '5px',
    };
  }
  return {
    '--vc-bg': '#FAFAFA',
    '--vc-color': 'var(--vc-gray-300)',
    '--vc-text-lg': '16px',
    '--vc-day-content-disabled-color': 'var(--vc-gray-600)',
    '--vc-weekday-color': '#FAFAFA',
    '--vc-rounded-full': '5px',
  };
});

// Watch for selectedDate changes
watch(
  () => props.selectedDate,
  async () => {
    date.value = props.selectedDate;
    await calendar.value?.move(date.value);
  }
);

const emit = defineEmits<{
  (e: 'dateSelected', value: string): void;
  (e: 'update:modelValue', value: string): void;
}>();

const disabledDates = computed(() => {
  return generateMissingDateRanges(props.availableDates);
});

function onUpdateDate(e: string | Date | null | undefined): void {
  const date = dayjs(e); // use dayjs to parse the date string

  if (date.isValid()) {
    const formattedDate = date.format('YYYY-MM-DD');
    emit('update:modelValue', formattedDate);
  } else {
    console.warn('Invalid date value:', e);
  }
}
</script>

<template>
  <div class="viewer-catalogue-calendar">
    <DatePicker ref="calendar"
                v-model="date"
                :style="calendarStyles"
                expanded
                borderless
                :is-inline="height > 600"
                :disabled-dates="disabledDates"
                mode="date"
                nav-visibility="none"
                :min-date="props.availableDates[0]"
                :max-date="props.availableDates.at(-1)"
                @update:model-value="onUpdateDate" />
  </div>
</template>
