<script lang="ts" setup>
import { computed } from 'vue';

import { BadgeVariant } from '@viewModels/enums';

interface BadgeProps {
  variant?: BadgeVariant;
  isPill?: boolean;
  isLarge?: boolean;
}
// Deconstruct Props
const { variant, isPill, isLarge } = defineProps<BadgeProps>();

// Badge classes
const badgeClasses = computed(() => {
  return ['badge', `badge--${variant || 'primary'}`, isPill && 'badge--pill', isLarge && 'badge--large'];
});
</script>

<template>
  <span :class="badgeClasses">
    <slot />
  </span>
</template>

<style lang="scss" scoped>
@use '@scss/variables' as *;

.badge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.5em 0.7em;
  font-size: 0.8em;
  font-weight: 500;
  line-height: 1;
  color: $neutral-50;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
  vertical-align: baseline;
  cursor: default;
  border-radius: 5px;

  &--primary {
    background-color: $primary-color;
  }

  &--secondary {
    background-color: $gold-600;
  }

  &--success {
    background-color: $green-400;
  }

  &--danger {
    background-color: $red-900;
  }

  &--warning {
    background-color: $orange-800;
  }

  &--info {
    background-color: $blue-600;
  }

  &--light {
    color: $neutral-800;
    background-color: $neutral-300;
  }

  &--dark {
    background-color: $neutral-700;
  }

  &--black {
    color: $neutral-50;
    background-color: $neutral-900;
  }

  &--transparent {
    background-color: transparent;
  }

  &--outline {
    color: $neutral-600;
    background-color: transparent;
    box-shadow: 0 0 0 1px $neutral-400;
  }

  &--pill {
    border-radius: 50rem;
  }

  &--large {
    font-size: 1rem;
  }

  &:empty {
    display: none;
  }
}
</style>
