<script setup lang="ts">
import { storeToRefs } from 'pinia';

import { useVideoStore } from '@stores/video';

const videoStore = useVideoStore();
const { videos, paginationOptions } = storeToRefs(videoStore);

const emit = defineEmits<{
  (e: 'onLoading'): void;
  (e: 'onLoaded'): void;
}>();

async function nextPage(): Promise<void> {
  emit('onLoading');
  paginationOptions.value.pageNumber++;
  await videoStore.getVideos(props.viewId);
  emit('onLoaded');
}

async function previousPage(): Promise<void> {
  emit('onLoading');
  paginationOptions.value.pageNumber--;
  if (paginationOptions.value.pageNumber < 0) paginationOptions.value.pageNumber = 1;
  await videoStore.getVideos(props.viewId);
  emit('onLoaded');
}

async function pageSizeChanged(): Promise<void> {
  emit('onLoading');
  paginationOptions.value.pageNumber = 1;
  await videoStore.getVideos(props.viewId);
  emit('onLoaded');
}

const props = defineProps({
  viewId: {
    type: String,
    required: false,
    default: '',
  },
});
</script>

<template>
  <div class="pagination">
    <hr class="pagination-rule">
    <div class="pagination-links">
      <div class="icon-group">
        <a v-if="paginationOptions.pageNumber > 1"
           href="javascript:;"
           class="icon icon-only icon-previous"
           @click="previousPage">
          Previous page
        </a>
        <a v-if="videos.offset + paginationOptions.pageSize < videos.total_count"
           href="javascript:;"
           class="icon icon-only icon-next"
           @click="nextPage">
          Next page
        </a>
      </div>
    </div>
    <div class="pagination-info">
      <p>
        {{ videos.offset + 1 }} to
        {{ videos.offset + paginationOptions.pageSize > videos.total_count
          ? videos.total_count
          : videos.offset + paginationOptions.pageSize }}
        of {{ videos.total_count }} results
      </p>
    </div>
    <div class="pagination-options">
      <v-select v-model="paginationOptions.pageSize"
                label="text"
                :options="[
                  { value: 12, text: '12 per page' },
                  { value: 24, text: '24 per page' },
                  { value: 36, text: '36 per page' },
                  { value: 48, text: '48 per page' },
                  { value: 60, text: '60 per page' },
                ]"
                :clearable="false"
                :searchable="false"
                :reduce="(value: any) => value.value"
                @option:selected="pageSizeChanged" />
    </div>
  </div>
</template>
