<script setup lang="ts">
import { onMounted, ref } from 'vue';
import * as yup from 'yup';
import timezones, { TimeZone } from 'timezones-list';
import * as client from '@gabrielcam/api-client';

import ContainerCard from '@components/cards/ContainerCard.vue';
import { ErrorMessage, useForm } from 'vee-validate';
import { ButtonType, ButtonVariant } from '@viewModels/enums';
import ButtonComponent from '@components/ButtonComponent.vue';
import ButtonActions from '@layouts/ButtonActions.vue';
import Loading from '@components/Loading.vue';
import { useApplicationStore } from '@stores/application';
import { UpdateUserRequest } from '@gabrielcam/api-client';
import Heading from '@components/Heading.vue';

const applicationStore = useApplicationStore();
let isLoading = false;
const userEmail = ref<string>();
const userStatus = ref<string>();

const props = defineProps<{
  userId: string
}>();

const emit = defineEmits<{
  (e: 'onUpdated'): void;
}>();

const { handleSubmit, resetForm, defineField, meta, isSubmitting } = useForm({
  validationSchema: yup.object({
    forename: yup.string().trim().required(),
    surname: yup.string().trim().required(),
    timezone: yup.string().trim().required(),
  }),
});

const [forenameValue] = defineField<string>('forename');
const [surnameValue] = defineField<string>('surname');
const [timezoneValue] = defineField<string>('timezone');

const onSubmit = handleSubmit(async (values): Promise<void> => {
  try {
    const request: client.UpdateUserByIdData = {
      userId: props.userId,
      requestBody: {
        displayName: values['forename'].trim(),
        forename: values['forename'].trim(),
        surname: values['surname'].trim(),
        timezone: values['timezone']
      },
    }
    const responseUser = await client.updateUserById(request);
    resetUserForm(responseUser)
    emit('onUpdated');
    applicationStore.publishSuccessNotification({
      text: 'Successfully updated.',
      autoCloseMs: 3000,
    });
  } catch {
    applicationStore.publishErrorNotification({ text: 'Error while updating.' });
    return;
  }
});

const resetUserForm = (user: UpdateUserRequest): void => {
  resetForm({
    values: {
      forename: user.forename,
      surname: user.surname,
      timezone: timezones.find(tz => tz.tzCode === user.timezone)?.tzCode
    }
  });
}

onMounted(async () => {
  const user = await client.getUserById({ userId: props.userId });
  resetUserForm(user)

  userEmail.value = user.email;
  userStatus.value = user.status;
  isLoading = false;
});
</script>

<template>
  <ContainerCard>
    <Loading v-if="isLoading" />
    <section v-else>
      <form @submit="onSubmit">
        <div class="field-group">
          <div class="field-group-info">
            <Heading level="3">
              User Information
            </Heading>
            <p>Edit user's information.</p>
          </div>
          <div class="fields">
            <div class="row-half">
              <div class="field">
                <label for="forename">Forename</label>
                <input id="forename"
                       v-model="forenameValue"
                       type="text">
                <ErrorMessage name="forename" class="message-error message" as="p" />
              </div>

              <div class="field">
                <label for="surname">Surname</label>
                <input id="surname"
                       v-model="surnameValue"
                       type="text">
                <ErrorMessage name="surname" class="message-error message" as="p" />
              </div>
            </div>

            <div class="row-half">
              <div class="field">
                <label for="email">Email</label>
                <input id="email"
                       v-model="userEmail"
                       disabled
                       readonly
                       type="text">
              </div>

              <div class="field">
                <label for="status">Status</label>
                <input id="status"
                       v-model="userStatus"
                       disabled
                       type="text">
              </div>
            </div>

            <div class="row-half">
              <div class="field">
                <label for="timezone">Timezone</label>
                <v-select id="timezone"
                          v-model="timezoneValue"
                          :reduce="(timezone: TimeZone) => timezone.tzCode"
                          :options="timezones" />
                <ErrorMessage name="timezone" class="message-error message" as="p" />
              </div>
            </div>
          </div>
        </div>

        <ButtonActions>
          <ButtonComponent :is-block-btn="true"
                           :type="ButtonType.Submit"
                           :variant="ButtonVariant.Dark"
                           :disabled="isSubmitting || !meta.dirty || !meta.valid">
            Update
          </ButtonComponent>
        </ButtonActions>
      </form>
    </section>
  </ContainerCard>
</template>
