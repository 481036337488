<script setup lang="ts">
import { ref } from 'vue';

import { PageNames } from '@viewModels/enums';

// import * as client from '@gabrielcam/api-client';
// import { useApplicationStore } from '@stores/application';
import NameSearch from '@components/toolbar/NameSearch.vue';

const emit = defineEmits<{
  (e: 'selectClient', value: string): void;
  (e: 'search', search: string | undefined, sort: string): any;
}>();

const props = defineProps({
  defaultSortBy: { type: String, required: false, default: 'lastCaptured' },
  isSticky: { type: Boolean, required: false, default: false },
});

// const applicationStore = useApplicationStore();
// const clients = ref([]) as Ref<client.Client[]>;
const filterDropdownOpen = ref<boolean>(false);
const sortDropdownOpen = ref<boolean>(false);
const selectedSortBy = ref<string>(
  props.defaultSortBy.startsWith('-') ? props.defaultSortBy.slice(1) : props.defaultSortBy
);
const selectedSortOrder = ref<string>(props.defaultSortBy.startsWith('') ? '' : '-');
const selectedSearchValue = ref<string>();

// async function obtainClientList(): Promise<void> {
//   const res = await client.listClients({ organisation: applicationStore.activeOrganisation!.id });
//   clients.value = res.data;
// }

// function toggleFilterDropdown(): void {
//   obtainClientList();
//   sortDropdownOpen.value = false;
//   filterDropdownOpen.value = !filterDropdownOpen.value;
// }
function toggleSortDropdown(): void {
  filterDropdownOpen.value = false;
  sortDropdownOpen.value = !sortDropdownOpen.value;
}

const sortOptions = [
  { value: 'name', displayValue: 'Name' },
  { value: 'lastCaptured', displayValue: 'Latest Image' },
];
const sortOrderOptions = [
  { value: '', displayValue: 'A-Z or Low to High' },
  { value: '-', displayValue: 'Z-A or High to Low' },
];

function updateSort(sortBy: string, sortOrder: string): void {
  emit('search', selectedSearchValue.value, `${sortOrder}${sortBy}`);
}
function search(searchValue: string): void {
  selectedSearchValue.value = searchValue;
  updateSort(selectedSortBy.value, selectedSortOrder.value);
}
</script>

<template>
  <div class="filters"
       :class="{ 'filters--sticky': isSticky }">
    <div class="icon-group">
      <router-link :to="{ name: PageNames.Views, params: {} }"
                   active-class="active"
                   class="icon icon-only icon-list">
        List View
      </router-link>
      <router-link :to="{ name: PageNames.ViewMap, params: {} }"
                   active-class="active"
                   class="icon icon-only icon-map">
        Map View
      </router-link>
    </div>
    <div class="filters-sort dropdown-toggle">
      <a href="javascript:;"
         class="icon icon-sort"
         :class="{ 'dropdown-open': sortDropdownOpen }"
         @click="toggleSortDropdown">
        Sort
      </a>
      <div class="dropdown">
        <div class="dropdown-item">
          <p class="dropdown-item-label">
            Sort on
          </p>
          <v-select v-model="selectedSortBy"
                    placeholder="Name"
                    label="displayValue"
                    :reduce="(value: any) => value.value"
                    :options="sortOptions"
                    :clearable="false"
                    @update:model-value="(value: string) => updateSort(value, selectedSortOrder)" />
        </div>
        <div class="dropdown-item">
          <p class="dropdown-item-label">
            Sort by
          </p>
          <v-select v-model="selectedSortOrder"
                    placeholder="A-Z or Low to High"
                    label="displayValue"
                    :reduce="(value: any) => value.value"
                    :options="sortOrderOptions"
                    :clearable="false"
                    @update:model-value="(value: string) => updateSort(selectedSortBy, value)" />
        </div>
      </div>
    </div>
    <!--  TODO: Filters
    <div class="filters-filter dropdown-toggle">
      <a href="javascript:;" class="icon icon-filter" :class="{ 'dropdown-open': filterDropdownOpen == true }" @click="toggleFilterDropdown">Filter <span class="count">0</span></a
      >
      <div class="dropdown">
        <div class="dropdown-item">
          <p class="dropdown-item-label">Client</p>
          <v-select
            placeholder="All clients"
            label="name"
            :options="clients"
            @update:modelValue="(client: client.Client) => emit('selectClient', client?.id)"
          ></v-select>
        </div>
        <div class="dropdown-item">
          <p class="dropdown-item-label">Status</p>
          <v-select placeholder="All statuses" :options="['OK', 'Warning', 'Error', 'Fatal']"></v-select>
        </div>
        <div class="dropdown-item">
          <p class="dropdown-item-label">Stage</p>
          <v-select placeholder="All stages" :options="['Live', 'Complete', 'Testing', 'Paused', 'Unknown']"></v-select>
        </div>
      </div>
    </div>
-->
    <NameSearch :on-search-function="search"
                :on-clear-function="search" />
  </div>
</template>

<style lang="scss" scoped>
@use '@scss/variables' as *;

.filters {
  display: flex;
  flex-wrap: wrap;
  gap: $gap-mobile;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: $margin-bottom;

  @media screen and (min-width: $breakpoint-lg) {
    gap: $gap-desktop;
    justify-content: start;
  }
}
</style>
