<script lang="ts" setup>
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';

import { useField, useForm } from 'vee-validate';
import * as yup from 'yup';

import * as client from '@gabrielcam/api-client';

import { useApplicationStore } from '@stores/application';
import { useViewStore, View } from '@stores/view';
import { ButtonType, ButtonVariant } from '@viewModels/enums';

import ButtonComponent from '@components/ButtonComponent.vue';
import ContainerCard from '@components/cards/ContainerCard.vue';
import Heading from '@components/Heading.vue';
import Loading from '@components/Loading.vue';
import ButtonActions from '@layouts/ButtonActions.vue';

interface ViewUpdateSourceForm {
  bucket?: string;
  prefix?: string;
}

const schema = yup.object({
  bucket: yup.string(),
  prefix: yup.string(),
});

const { handleSubmit } = useForm<ViewUpdateSourceForm>({
  validationSchema: schema,
});

const { value: bucketValue, errorMessage: bucketError } = useField<string | undefined>('bucket', 'bucket');
const { value: prefixValue, errorMessage: prefixError } = useField<string | undefined>('prefix', 'prefix');

const route = useRoute();
const viewStore = useViewStore();
const applicationStore = useApplicationStore();

const viewId = route.params['id'] as string;

const isLoading = ref<boolean>(true);
const isSubmitting = ref<boolean>(false);

const currentView = ref<View>();

const onSubmitViewSource = handleSubmit(async (values) => {
  isSubmitting.value = true;

  const request: client.SetViewSourceRequest = {
    provider: client.provider.AWS,
    region: 'eu-west-1',
    bucket: values.bucket ?? '',
    prefix: values.prefix ?? '',
    credentialId: '1234',
  };

  const response = await viewStore.updateViewSource(viewId, request);
  isSubmitting.value = false;

  if (response.error !== undefined) {
    applicationStore.publishErrorNotification({ text: response.error });
    return;
  }

  applicationStore.publishSuccessNotification({
    text: 'Successfully updated view.',
    autoCloseMs: 3000,
  });
});


const fetchViewData = async (): Promise<void> => {
  currentView.value = await viewStore.getViewById(viewId);
  bucketValue.value = currentView.value.source?.bucket;
  prefixValue.value = currentView.value.source?.prefix!;
};

onMounted(async () => {
  await fetchViewData();

  isLoading.value = false;
});
</script>

<template>
  <ContainerCard>
    <Loading v-if="isLoading" />

    <div v-else>
      <form @submit="onSubmitViewSource">
        <div v-if="applicationStore.adminMode"
             class="field-group">
          <div class="field-group-info">
            <Heading level="3">
              View Source
            </Heading>
            <p>Set a views source</p>
          </div>

          <div class="fields">
            <div class="field">
              <label for="source-bucket">Bucket</label>
              <input id="source-bucket"
                     v-model="bucketValue"
                     type="text">
              <p class="message message-error">
                {{ bucketError }}
              </p>
            </div>
            <div class="field">
              <label for="source-prefix">Prefix</label>
              <input id="source-prefix"
                     v-model="prefixValue"
                     type="text">
              <p class="message message-error">
                {{ prefixError }}
              </p>
            </div>
          </div>
        </div>

        <ButtonActions>
          <ButtonComponent :variant="ButtonVariant.Dark"
                           :disabled="isSubmitting"
                           :is-block-btn="true"
                           :type="ButtonType.Submit">
            Update
          </ButtonComponent>
        </ButtonActions>
      </form>
    </div>
  </ContainerCard>
</template>

<style lang="scss" scoped>
.checkbox-field {
  & .checkbox-label {
    margin-left: 10px;

    & .word-wrap {
      display: inline-block;
      width: 100%;
    }

    & .break-word {
      word-break: break-all;
      word-wrap: break-word;
      overflow-wrap: break-word;
    }
  }

  & input[type='checkbox'] {
    flex-shrink: 0;
  }
}
</style>
