<script lang="ts" setup>
import { onMounted, Ref, ref, watch } from 'vue';
import Loading from '@components/Loading.vue';
import ImageFrame from './ImageFrame.vue';
import { ViewerImage } from '@/types';

interface Props {
  viewName: string;
  leftImage: ViewerImage;
  rightImage?: ViewerImage | null;
  imageDate: string;
  maxScale?: number;
  opacity?: number;
  overlaid?: boolean;
  zoom?: {
    scale: number;
    x: number;
    y: number;
  };
}

const loading = ref<boolean>(true);
const props = withDefaults(defineProps<Props>(), {
  maxScale: 50,
  opacity: 1,
  overlaid: false,
  rightImage: null,
  zoom: () => ({ scale: 1, x: 0, y: 0 }),
});

defineExpose({
  init,
  scaleTo,
  setTransform,
});

const frame = ref(null) as Ref<typeof ImageFrame | null>;
const leftImageUrl = ref<string>();
const rightImageUrl = ref<string>();

watch(
  () => props.leftImage,
  () => {
    if (props.leftImage.sourceURL == undefined) return;
    loading.value = true;
    leftImageUrl.value = props.leftImage.sourceURL + '?auto=enhance&sharp=66';
  },
  { immediate: true }
);

watch(
  () => props.rightImage,
  () => {
    if (props.rightImage?.sourceURL == undefined) return;
    loading.value = true;
    rightImageUrl.value = props.rightImage.sourceURL + '?auto=enhance&sharp=66';
  },
  { immediate: true }
);

onMounted(() => {});

function init(): void {
  if (frame.value) frame.value['init']();
}

function scaleTo(scale: number, options: any): void {
  if (frame.value) frame.value['scaleTo'](scale, options);
}

function setTransform(zoom: any): void {
  if (frame.value) frame.value['setTransform'](zoom);
}
</script>

<template>
  <div :id="'frame' + leftImage.id"
       class="frame">
    <ImageFrame v-if="leftImage.sourceURL"
                ref="frame"
                :left-image-src="leftImageUrl!"
                :max-scale="maxScale"
                :right-image-src="rightImageUrl"
                @image-loaded="loading = false" />

    <slot name="frame-controls" />

    <div v-if="!overlaid"
         class="viewer-date">
      <p>{{ imageDate }}</p>
    </div>

    <div v-if="!overlaid"
         class="viewer-name">
      <p>{{ viewName }}</p>
    </div>

    <Loading v-if="loading"
             :is-absolute-positioned="true" />
  </div>
</template>
