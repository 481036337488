import { Ref, ref } from 'vue';

import { defineStore, storeToRefs } from 'pinia';

// import * as client from '@gabrielcam/api-client';
import { Entitlements } from '@gabrielcam/api-client';

import { useApplicationStore } from '@stores/application';
import { AdminMenuTitles, MainMenuTitles, SubscriptionMenuTitles } from '@viewModels/enums';
import { IconName, IconStyle } from '@viewModels/heroIcons';

export interface NavItem {
  to?: string;
  icon?: IconName;
  iconStyle?: IconStyle;
  title?: string;
  viewPermission?: boolean;
  children?: NavItem[];
  isOpen?: boolean;
  isDropdown?: boolean;
  separator?: boolean;
}

export const useNavStore = defineStore('useNavStore', () => {
  const navItems: Ref<NavItem[]> = ref([]);

  const applicationStore = useApplicationStore();
  const { adminMode } = storeToRefs(applicationStore);

  // Set View Permissions
  // const createView = applicationStore.canUser(Entitlements.CREATE_VIEW, applicationStore.activeOrganisation!);
  // const createVideo = applicationStore.canUser(Entitlements.CREATE_SEQUENCE, applicationStore.activeOrganisation!);
  const viewCameras = applicationStore.canUser(Entitlements.READ_CAMERA, applicationStore.activeOrganisation!);
  // const createCamera = applicationStore.canUser(client.Entitlements.CREATE_CAMERA, applicationStore.activeOrganisation!);
  // const createUser = applicationStore.canUser(Entitlements.CREATE_USER, applicationStore.activeOrganisation!);

  // Main Menu
  function setMainMenu(): void {
    navItems.value = [
      {
        to: '/views/',
        icon: IconName.HomeIcon,
        iconStyle: IconStyle.Solid,
        title: MainMenuTitles.AllViews,
      },
      {
        to: '/videos/',
        icon: IconName.VideoCameraIcon,
        iconStyle: IconStyle.Solid,
        title: MainMenuTitles.Videos,
      },
      {
        separator: true,
        viewPermission: viewCameras || adminMode,
      },
      {
        to: '/cameras',
        icon: IconName.CameraIcon,
        iconStyle: IconStyle.Solid,
        title: MainMenuTitles.Cameras,
        viewPermission: viewCameras,
      },
      {
        to: '/subscriptions',
        icon: IconName.UserGroupIcon,
        iconStyle: IconStyle.Solid,
        title: MainMenuTitles.Subscriptions,
        viewPermission: adminMode,
        isOpen: false,
        children: [
          { to: '/subscriptions/views', title: SubscriptionMenuTitles.Views, viewPermission: adminMode },
          // { to: '/subscriptions/notifications', title: SubscriptionMenuTitles.Notifications },
          { to: '/subscriptions/projects', title: SubscriptionMenuTitles.Projects, viewPermission: adminMode },
          { to: '/subscriptions/clients', title: SubscriptionMenuTitles.Clients, viewPermission: adminMode },
          // { to: '/subscriptions/licenses', title: SubscriptionMenuTitles.Licenses },
        ],
      },
      {
        to: '/admin',
        icon: IconName.Cog6ToothIcon,
        iconStyle: IconStyle.Solid,
        title: MainMenuTitles.Admin,
        viewPermission: adminMode,
        isOpen: false,
        children: [
          { to: '/admin', title: MainMenuTitles.Admin },
          { to: '/organisations', title: AdminMenuTitles.Organisations },
          { to: '/users', title: AdminMenuTitles.Users },
          { to: '/events', title: AdminMenuTitles.EventStreams },
        ],
      },
    ] as NavItem[];
  }

  return {
    navItems,
    setMainMenu,
  };
});
