import { computed, Ref, ref } from 'vue';
import { useRouter } from 'vue-router';
import { defineStore } from 'pinia';
import * as client from '@gabrielcam/api-client';

import { BaseModel, can } from '@utils/access';
import { getContinueUrl } from '@utils/continueUrl';
import { getCssClass, setTimeoutNotification } from '@utils/notifications';

import { NotificationState, PageNames } from '@viewModels/enums';
import { Notification } from '@utils/notifications';

export interface NotificationOptions {
  text?: string;
  autoCloseMs?: number;
}

export const useApplicationStore = defineStore(
  'application',
  () => {
    const router = useRouter();
    const applicationReady = ref<boolean>(false);
    const currentNotification: Ref<Notification | undefined> = ref(undefined);
    const activeUser = ref<client.User>();
    const activeUserRoles = ref<Array<client.AssignedRole>>();
    const adminMode = computed(() => activeUser.value?.isAdmin || false);
    const organisationList = ref<Array<client.Organisation>>();
    const activeOrganisation = ref<client.Organisation>();
    const defaultTheme: client.OrganisationSettings = {
      logoBase64: '/logos/gabrielcam-logo.png',
      primaryColour: '#17214b',
      secondaryColour: '#cd102e',
    };
    const isTimeLapseSystems = ref(window.location.hostname.includes('time-lapse-systems.co.uk'));

    function publishErrorNotification(options: NotificationOptions): void {
      const cssClass: string = getCssClass(NotificationState.Error);
      currentNotification.value = { text: options.text, state: NotificationState.Error, class: cssClass };
      setTimeoutNotification(currentNotification, options.autoCloseMs);
    }

    function publishSuccessNotification(options: NotificationOptions): void {
      const cssClass: string = getCssClass(NotificationState.Success);
      currentNotification.value = { text: options.text, state: NotificationState.Success, class: cssClass };
      setTimeoutNotification(currentNotification, options.autoCloseMs);
    }

    async function setActiveUser(): Promise<void> {
      let newUser;
      try {
        newUser = await client.getUserSelf({ expand: [client.Resources_Organisation.ORGANISATION] });
      } catch {
        activeUser.value = undefined;
        return;
      }

      activeUser.value = newUser;
      activeUser.value.isAdmin = true; // bodge
      activeUserRoles.value = await client.getUserSelfRoles();
      organisationList.value = activeUser.value.organisations as Array<client.Organisation>;

      let organisation = organisationList.value[0];

      // Check if the user has an active organisation and it is still in their roles
      if (
        activeOrganisation.value &&
        organisationList.value.map((org) => org.id).includes(activeOrganisation.value.id)
      ) {
        organisation = activeOrganisation.value;
      }

      if (organisation) {
        await setActiveOrganisation(organisation.id);
      } else {
        console.error('No organisation found');
        await router.push({ name: PageNames.Logout, query: { message: 'No organisation found.' } });
        return;
      }

      let continueUrl = getContinueUrl(router);

      if (activeUser.value.status === client.UserStatus.INVITED) {
        router.push({ name: PageNames.Welcome, query: { continueUrl } });
      } else {
        router.push(continueUrl ? { path: continueUrl } : { name: PageNames.Views });
      }
    }

    function canUser(action: client.Entitlements, doc: BaseModel): boolean {
      const user = activeUser.value;
      const userRoles = activeUserRoles.value;

      if (user === undefined || userRoles === undefined) return false;
      if (user.isAdmin) return true;

      return can(userRoles, action, doc);
    }

    async function setActiveOrganisation(organisationId: string): Promise<void> {
      const currentRoute = router.currentRoute.value;
      const previousOrganisation = activeOrganisation.value?.id;

      activeOrganisation.value = await client.getOrganisationById({ organisationId: organisationId }) as client.Organisation;

      if (previousOrganisation && previousOrganisation !== activeOrganisation.value.id) {
        location.replace(currentRoute.path || '/views');
      }
    }

    return {
      adminMode,
      applicationReady,
      currentNotification,
      activeUser,
      organisationList,
      activeOrganisation,
      publishErrorNotification,
      publishSuccessNotification,
      setActiveUser,
      canUser,
      setActiveOrganisation,
      defaultTheme,
      isTimeLapseSystems,
    };
  },
  {
    persist: {
      paths: ['activeOrganisation'],
    },
  },
);
