<script lang="ts" setup>
import { onMounted, onUnmounted, ref } from 'vue';
import { useRoute } from 'vue-router';

import { useField, useForm } from 'vee-validate';
import * as yup from 'yup';

import * as client from '@gabrielcam/api-client';

import { useApplicationStore } from '@stores/application';
import { ButtonType, ButtonVariant, FetchStatus, StepStatus } from '@viewModels/enums';

import ButtonComponent from '@components/ButtonComponent.vue';
import ContainerCard from '@components/cards/ContainerCard.vue';
import FeatureFlag from '@components/FeatureFlag.vue';
import Heading from '@components/Heading.vue';
import Loading from '@components/Loading.vue';
import MultiStepProgress, { MultiStepItems } from '@components/MultiStepProgress.vue';
import ButtonActions from '@layouts/ButtonActions.vue';
import Tooltip from '@components/Tooltip.vue';

interface TestForm {
  iso: string;
  picturestyle: string;
  aperture: string;
  whitebalance: string;
  exposurecompensation: string;
  imageformat: string;

  [key: string]: string | undefined;
}

const schema = yup.object({
  iso: yup.string(),
  picturestyle: yup.string(),
  aperture: yup.string(),
  whitebalance: yup.string(),
  exposurecompensation: yup.string(),
  imageformat: yup.string(),
});

const { handleSubmit } = useForm<TestForm>({
  validationSchema: schema,
});

const { value: isoValue } = useField<string | undefined>('iso', 'iso');
const { value: pictureStyleValue } = useField<string | undefined>('picturestyle', 'picturestyle');
const { value: apertureValue } = useField<string | undefined>('aperture', 'aperture');
const { value: whitebalanceValue } = useField<string | undefined>('whitebalance', 'whitebalance');
const { value: exposureCompensationValue } = useField<string | undefined>(
  'exposurecompensation',
  'exposurecompensation'
);
const { value: imageFormatValue } = useField<string | undefined>('imageformat', 'imageformat');

const timedOutTimeLimitSecs = ref<number>(10);
const timedOutTimeSecs = ref<number>(0);

const desiredIso = ref<string>();
const desiredPictureStyle = ref<string>();
const desiredAperture = ref<string>();
const desiredWhitebalance = ref<string>();
const desiredExposureCompensation = ref<string>();
const desiredImageFormat = ref<string>();

const reportedIso = ref<string>();
const reportedPictureStyle = ref<string>();
const reportedAperture = ref<string>();
const reportedWhitebalance = ref<string>();
const reportedExposureCompensation = ref<string>();
const reportedImageFormat = ref<string>();

const shadow = ref<client.Shadow>();

const route = useRoute();
const applicationStore = useApplicationStore();
const isSubmitting = ref<boolean>(false);
const cameraId = route.params['id'] as string;
const currentCamera = ref<client.Camera>();
const isLoading = ref<boolean>(true);
let shadowInterval: NodeJS.Timeout | null = null;

const onSubmit = handleSubmit(async (values) => {
  isSubmitting.value = true;
  timedOutTimeSecs.value = 0;
  Object.keys(values).forEach((key) => values[key] === undefined && delete values[key]);

  if (applicationStore.activeOrganisation == undefined) return;
  var desired = shadow?.value?.state?.reported ?? {};
  desired['iso'] = values.iso;
  desired['picturestyle'] = values.picturestyle;
  desired['aperture'] = values.aperture;
  desired['whitebalance'] = values.whitebalance;
  desired['exposurecompensation'] = values.exposurecompensation;
  desired['imageformat'] = values.imageformat;
  try {
    shadow.value = await client.updateCameraByIdShadow({
      cameraId,
      requestBody: {
        state: {
          desired: desired,
        },
      },
    });
    // textarea.value!.value = JSON.stringify(shadow.value, null, 2);
    isSubmitting.value = false;
    applicationStore.publishSuccessNotification({
      text: 'Successfully updated camera.',
      autoCloseMs: 3000,
    });
  } catch (error: any) {
    if (error instanceof client.ApiError) {
      // @ts-ignore
      applicationStore.publishErrorNotification({ text: error.body.error.message });
    }
    applicationStore.publishErrorNotification({ text: 'UNKNOWN ERROR' });
    isSubmitting.value = false;
    return;
  }
});

async function fetchShadow(): Promise<void> {
  shadow.value = await client.getCameraByIdShadow({ cameraId });

  // Log the shadow
  console.log('Desired & Reported States');
  console.table(shadow.value.state);

  // const textAreaValue = textarea.value;
  // if (textAreaValue != null) textAreaValue.value = JSON.stringify(shadow.value, null, 2);

  if (shadow.value.state.desired != undefined) {
    desiredIso.value = shadow.value.state.desired['iso'] as string; // The type on these should at least be a string.
    desiredPictureStyle.value = shadow.value.state.desired['picturestyle'] as string; // The type on these should at least be a string.
    desiredAperture.value = shadow.value.state.desired['aperture'] as string; // The type on these should at least be a string.
    desiredWhitebalance.value = shadow.value.state.desired['whitebalance'] as string; // The type on these should at least be a string.
    desiredExposureCompensation.value = shadow.value.state.desired['exposurecompensation'] as string; // The type on these should at least be a string.
    desiredImageFormat.value = shadow.value.state.desired['imageformat'] as string; // The type on these should at least be a string.
  }
  if (shadow.value.state.reported != undefined) {
    reportedIso.value = shadow.value.state.reported['iso'] as string;
    reportedPictureStyle.value = shadow.value.state.reported['picturestyle'] as string;
    reportedAperture.value = shadow.value.state.reported['aperture'] as string;
    reportedWhitebalance.value = shadow.value.state.reported['whitebalance'] as string;
    reportedExposureCompensation.value = shadow.value.state.reported['exposurecompensation'] as string;
    reportedImageFormat.value = shadow.value.state.reported['imageformat'] as string;
  }

  isoValue.value = isoValue.value ?? reportedIso.value;
  pictureStyleValue.value = pictureStyleValue.value ?? reportedPictureStyle.value;
  apertureValue.value = apertureValue.value ?? reportedAperture.value;
  whitebalanceValue.value = whitebalanceValue.value ?? reportedWhitebalance.value;
  exposureCompensationValue.value = exposureCompensationValue.value ?? reportedExposureCompensation.value;
  imageFormatValue.value = imageFormatValue.value ?? reportedImageFormat.value;
}

onMounted(async () => {
  await fetchShadow();
  shadowInterval = setInterval(async () => {
    await fetchShadow();
    if (shadow.value?.state.desired != undefined) {
      timedOutTimeSecs.value++;
    } else {
      timedOutTimeSecs.value = 0;
    }
  }, 1000);
});

onUnmounted(() => {
  if (shadowInterval) clearInterval(shadowInterval);
});

// Get current camera data
onMounted(async () => {
  try {
    currentCamera.value = await client.getCameraById({ cameraId });
  } catch (error) {
    console.error('Error fetching camera data:', error);
  } finally {
    isLoading.value = false;

    // MultiStep Demo
    await startTasks();
  }
});

// MultiStep
const steps = ref<MultiStepItems[]>([
  { label: 'Current Settings', status: StepStatus.Completed, fetchStatus: FetchStatus.Success },
  { label: 'Desired Settings', status: StepStatus.Default, fetchStatus: FetchStatus.Idle },
  { label: 'Received Settings', status: StepStatus.Default, fetchStatus: FetchStatus.Idle },
  { label: 'Updated Settings', status: StepStatus.Default, fetchStatus: FetchStatus.Idle },
]);

// MultiStep - Simulate asynchronous tasks that update the fetchStatus
const runBackgroundTask = async (stepIndex: number): Promise<void> => {
  if (!steps.value[stepIndex]) {
    console.error(`Step at index ${stepIndex} is undefined`);
    return;
  }

  // Set fetchStatus to 'fetching' before the task starts
  steps.value[stepIndex].fetchStatus = FetchStatus.Fetching;

  try {
    // Simulate a delay for the background task
    await new Promise((resolve, reject) =>
      setTimeout(() => {
        // Simulate an error on stepIndex 2 (Received) to show the error state
        if (stepIndex === 2) {
          reject('Simulated error on step 3');
        } else {
          resolve(true);
        }
      }, 5000)
    ); // 5 seconds delay

    // If the task succeeds, update the fetchStatus
    steps.value[stepIndex].status = StepStatus.Completed;
    steps.value[stepIndex].fetchStatus = FetchStatus.Success;

    // Set the next step's status to Pending
    if (steps.value[stepIndex + 1]) {
      steps.value[stepIndex + 1]!.status = StepStatus.Pending;
    }
  } catch (error) {
    // Handle error state and stop further tasks
    console.error(error);
    steps.value[stepIndex].status = StepStatus.Error;
    steps.value[stepIndex].fetchStatus = FetchStatus.Error;

    throw new Error(`Process stopped at step ${stepIndex + 1} due to an error.`);
  }
};

// MultiStep - Start tasks in sequence
const startTasks = async (): Promise<void> => {
  try {
    for (let i = 0; i < steps.value.length; i++) {
      // Run task and stop if there's an error
      await runBackgroundTask(i);
    }
  } catch (error) {
    console.error('Task sequence terminated:', error);
  }
};
</script>

<template>
  <ContainerCard>
    <Loading v-if="isLoading" />

    <section v-else>
      <FeatureFlag name="xCameraSettings">
        <MultiStepProgress :steps="steps" />
      </FeatureFlag>

      <form @submit="onSubmit">
        <div class="field-group">
          <div class="field-group-info">
            <Heading level="3">
              Camera Settings
            </Heading>
            <p>Update the technical aspects of the camera.</p>
          </div>

          <div class="fields">
            <div class="row-half">
              <div v-if="!shadow?.state.desired || timedOutTimeSecs > timedOutTimeLimitSecs"
                   class="field">
                <label>Current ISO</label>
                <v-select v-model="isoValue"
                          :options="['Auto', '100', '200', '400', '800', '1600', '3200', '6400']" />
              </div>
              <div v-if="shadow?.state.desired && timedOutTimeSecs < timedOutTimeLimitSecs"
                   class="field">
                <label>Current ISO</label>
                <v-select v-model="reportedIso"
                          :clearable="false"
                          :disabled="true"
                          :no-drop="true"
                          :options="['Auto', '100', '200', '400', '800', '1600', '3200', '6400']" />
              </div>
              <div v-if="shadow?.state.desired && timedOutTimeSecs < timedOutTimeLimitSecs"
                   class="field">
                <label>Pending ISO</label>
                <v-select v-model="desiredIso"
                          :clearable="false"
                          :disabled="true"
                          :no-drop="true"
                          :options="['Auto', '100', '200', '400', '800', '1600', '3200', '6400']" />
              </div>
            </div>

            <div class="row-half">
              <div v-if="!shadow?.state.desired || timedOutTimeSecs > timedOutTimeLimitSecs"
                   class="field">
                <label for="model">Current Picture Style</label>
                <v-select v-model="pictureStyleValue"
                          :options="['Neutral', 'Standard', 'Portrait', 'Landscape']" />
              </div>
              <div v-if="shadow?.state.desired && timedOutTimeSecs < timedOutTimeLimitSecs"
                   class="field">
                <label>Current Picture Style</label>
                <v-select v-model="reportedPictureStyle"
                          :clearable="false"
                          :disabled="true"
                          :no-drop="true"
                          :options="['Neutral', 'Standard', 'Portrait', 'Landscape']" />
              </div>
              <div v-if="shadow?.state.desired && timedOutTimeSecs < timedOutTimeLimitSecs"
                   class="field">
                <label>Pending Picture Style</label>
                <v-select v-model="desiredPictureStyle"
                          :clearable="false"
                          :disabled="true"
                          :no-drop="true"
                          :options="['Neutral', 'Standard', 'Portrait', 'Landscape']" />
              </div>
            </div>
            <div class="row-half">
              <div v-if="!shadow?.state.desired || timedOutTimeSecs > timedOutTimeLimitSecs"
                   class="field">
                <label for="model">
                  Current Aperture <Tooltip>Recommend F/8.0 as deep focus setting.</Tooltip>
                </label>

                <v-select v-model="apertureValue"
                          :options="['5.6', '6.3', '7.1', '8.0']" />
              </div>
              <div v-if="shadow?.state.desired && timedOutTimeSecs < timedOutTimeLimitSecs"
                   class="field">
                <label for="model">
                  Current Aperture <Tooltip>Recommend F/8.0 as deep focus setting.</Tooltip>
                </label>
                <v-select v-model="reportedAperture"
                          :clearable="false"
                          :disabled="true"
                          :no-drop="true" />
              </div>
              <div v-if="shadow?.state.desired && timedOutTimeSecs < timedOutTimeLimitSecs"
                   class="field">
                <label for="model">
                  Pending Aperture <Tooltip>Recommend F/8.0 as deep focus setting.</Tooltip>
                </label>
                <v-select v-model="desiredAperture"
                          :clearable="false"
                          :disabled="true"
                          :no-drop="true" />
              </div>
            </div>
            <div class="row-half">
              <div v-if="!shadow?.state.desired || timedOutTimeSecs > timedOutTimeLimitSecs"
                   class="field">
                <label for="model">Current White Balance</label>
                <v-select v-model="whitebalanceValue"
                          :options="['Auto', 'Daylight', 'cloudy', 'Tungsten']" />
              </div>
              <div v-if="shadow?.state.desired && timedOutTimeSecs < timedOutTimeLimitSecs"
                   class="field">
                <label for="model">Current White Balance</label>
                <v-select v-model="reportedWhitebalance"
                          :clearable="false"
                          :disabled="true"
                          :no-drop="true" />
              </div>
              <div v-if="shadow?.state.desired && timedOutTimeSecs < timedOutTimeLimitSecs"
                   class="field">
                <label for="model">Pending White Balance</label>
                <v-select v-model="desiredWhitebalance"
                          :clearable="false"
                          :disabled="true"
                          :no-drop="true" />
              </div>
            </div>
            <div class="row-half">
              <div v-if="!shadow?.state.desired || timedOutTimeSecs > timedOutTimeLimitSecs"
                   class="field">
                <label for="model">
                  Current Exposure Compensation <Tooltip>Can be adjusted to manually override the image exposure to make the image brighter or darker.</Tooltip>
                </label>
                <v-select v-model="exposureCompensationValue"
                          :options="['0']" />
              </div>
              <div v-if="shadow?.state.desired && timedOutTimeSecs < timedOutTimeLimitSecs"
                   class="field">
                <label for="model">
                  Current Exposure Compensation <Tooltip>Can be adjusted to manually override the image exposure to make the image brighter or darker.</Tooltip>
                </label>
                <v-select v-model="reportedExposureCompensation"
                          :clearable="false"
                          :disabled="true"
                          :no-drop="true" />
              </div>
              <div v-if="shadow?.state.desired && timedOutTimeSecs < timedOutTimeLimitSecs"
                   class="field">
                <label for="model">
                  Pending Exposure Compensation <Tooltip>Can be adjusted to manually override the image exposure to make the image brighter or darker.</Tooltip>
                </label>
                <v-select v-model="desiredExposureCompensation"
                          :clearable="false"
                          :disabled="true"
                          :no-drop="true" />
              </div>
            </div>
            <div class="row-half">
              <div v-if="!shadow?.state.desired || timedOutTimeSecs > timedOutTimeLimitSecs"
                   class="field">
                <label for="model">Current Image Format</label>
                <v-select v-model="imageFormatValue"
                          :options="['Large Fine JPEG', 'Large Normal JPEG', 'RAW']" />
              </div>
              <div v-if="shadow?.state.desired && timedOutTimeSecs < timedOutTimeLimitSecs"
                   class="field">
                <label for="model">Current Image Format</label>
                <v-select v-model="reportedImageFormat"
                          :clearable="false"
                          :disabled="true"
                          :no-drop="true" />
              </div>
              <div v-if="shadow?.state.desired && timedOutTimeSecs < timedOutTimeLimitSecs"
                   class="field">
                <label for="model">Pending Image Format</label>
                <v-select v-model="reportedImageFormat"
                          :clearable="false"
                          :disabled="true"
                          :no-drop="true" />
              </div>
            </div>
          </div>
        </div>

        <ButtonActions>
          <ButtonComponent :variant="ButtonVariant.Dark"
                           :disabled="isSubmitting"
                           :is-block-btn="true"
                           :type="ButtonType.Submit">
            Update
          </ButtonComponent>
        </ButtonActions>
      </form>
    </section>
  </ContainerCard>
</template>
