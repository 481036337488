<script setup lang="ts">
import { PropType } from 'vue';

import { CardVariant } from '@viewModels/enums';

// Card Props
const props = defineProps({
  variant: {
    type: String as PropType<CardVariant>,
    default: CardVariant.Light,
  },
  hasBorder: {
    type: Boolean,
    default: false,
  },
  marginBottom: {
    type: Boolean,
    default: true,
  },
  narrowCard: {
    type: Boolean,
    default: false,
  },
  isSticky: {
    type: Boolean,
    default: false,
  },
  hasShadow: {
    type: Boolean,
    default: true,
  }
});

defineExpose({ props });

const variantStyles = {
  [CardVariant.Primary]: 'primary',
  [CardVariant.Secondary]: 'secondary',
  [CardVariant.Success]: 'success',
  [CardVariant.Danger]: 'danger',
  [CardVariant.Warning]: 'warning',
  [CardVariant.Light]: 'light',
  [CardVariant.Dark]: 'dark',
  [CardVariant.LightGrey]: 'light-grey',
  [CardVariant.TransparentDark]: 'transparent-dark',
  [CardVariant.TransparentLight]: 'transparent-light',
};

function getCardClasses(variant: CardVariant, hasBorder: boolean): string {
  const baseClass = variantStyles[variant];
  // If hasBorder is true, return only the borderClass, otherwise return baseClass
  return hasBorder ? `${baseClass}--border` : baseClass;
}
</script>

<template>
  <div :class="[
    'container-card',
    getCardClasses(variant, hasBorder),
    {
      'mb-0': !marginBottom,
      'container-card--sticky': isSticky,
      'container-card--narrow': narrowCard,
      'container-card--shadow': hasShadow,
    },
  ]">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
@use '@/scss/variables' as *;

.container-card {
  padding: clamp($gap-mobile, 2vw, $gap-desktop);
  margin-bottom: $margin-bottom;
  border-radius: 10px;

  &--shadow {
    box-shadow: $box-shadow;
  }

  &--narrow {
    padding-top: $gap-mobile;
    padding-bottom: $gap-mobile;
  }

  &--sticky {
    position: sticky;
    top: 0;
    z-index: 2;
  }

  &.mb-0 {
    margin-bottom: 0;
  }

  // Themes
  &.primary {
    color: $neutral-50;
    background-color: $purple-800;

    &--border {
      color: $neutral-50;
      background-color: $purple-800;
      box-shadow: inset 0 0 0 1px $neutral-800;
    }
  }

  &.secondary {
    color: $neutral-50;
    background-color: $secondary-color;

    &--border {
      color: $neutral-50;
      background-color: $secondary-color;
      box-shadow: inset 0 0 0 1px $neutral-800;
    }
  }

  &.success {
    color: $neutral-50;
    background-color: $green-800;

    &--border {
      color: $neutral-50;
      background-color: $green-800;
      box-shadow: inset 0 0 0 1px $green-900;
    }
  }

  &.danger {
    color: $neutral-50;
    background-color: $red-800;

    &--border {
      color: $neutral-50;
      background-color: $red-800;
      box-shadow: inset 0 0 0 1px $red-900;
    }
  }

  &.warning {
    color: $neutral-50;
    background-color: $orange-800;

    &--border {
      color: $neutral-50;
      background-color: $orange-800;
      box-shadow: inset 0 0 0 1px $orange-900;
    }
  }

  &.light {
    color: $neutral-800;
    background-color: $neutral-50;

    &--border {
      color: $neutral-800;
      background-color: $neutral-50;
      box-shadow: inset 0 0 0 1px $neutral-400;
    }
  }

  &.dark {
    color: $neutral-50;
    background-color: $neutral-800;

    &--border {
      color: $neutral-50;
      background-color: $neutral-800;
      box-shadow: inset 0 0 0 1px $neutral-500;
    }
  }

  &.light-grey {
    color: $neutral-800;
    background-color: $neutral-200;

    &--border {
      color: $neutral-800;
      background-color: $neutral-200;
      box-shadow: inset 0 0 0 1px $neutral-400;
    }
  }

  &.transparent-dark {
    background: $black-opacity-50;
    backdrop-filter: blur(2px);

    &--border {
      background: $black-opacity-50;
      backdrop-filter: blur(2px);
      box-shadow: inset 0 0 0 1px $neutral-600;
    }
  }

  &.transparent-light {
    background: $white-opacity-50;
    backdrop-filter: blur(2px);

    &--border {
      background: $white-opacity-50;
      backdrop-filter: blur(2px);
      box-shadow: inset 0 0 0 1px $neutral-600;
    }
  }
}
</style>
