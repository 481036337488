import { ref } from 'vue';

import { defineStore } from 'pinia';

import * as client from '@gabrielcam/api-client';
import { GetCameraByIdStatLatestData } from '@gabrielcam/api-client';

import { MapMarker } from '@viewModels/mapMarker';

import { useApplicationStore } from './application';


export interface PaginationOptions {
    enabled: boolean;
    pageNumber: number;
    pageSize: number;
}

export interface PagedResult<T> {
    total_count: number;
    offset: number;
    pageSize?: number;
    pageNumber?: number;
    data: Array<T>;
}

export interface View extends client.View {
    lastSeen?: Date;
    lastCaptured?: Date;
}

export const useViewStore = defineStore('useViewStore', () => {
    const applicationStore = useApplicationStore();
    const viewCollectionRef = ref<PagedResult<View>>({ data: [], total_count: 0, offset: 0 });
    const paginationOptions = ref<PaginationOptions>({ pageNumber: 1, pageSize: 12, enabled: true });
    const viewSelected = ref<View>();
    const viewSelectedForDeletion = ref<View>();
    const datePickerIsOpen = ref<boolean>(false);

    async function obtainViewListLocalPagination(search: string | undefined = undefined, sortBy: string) {
        const pageNumber = paginationOptions.value.enabled ? paginationOptions.value.pageNumber : 1;
        const pageSize = paginationOptions.value.enabled ? paginationOptions.value.pageSize : Number.MAX_SAFE_INTEGER;
        const res = await client.listViews({ organisation: applicationStore.activeOrganisation!.id });
        let views = res.data.map((view) => {
            return {
                ...view,
                lastSeen: view?.lastSeenUtc !== undefined ? new Date(view?.lastSeenUtc) : undefined,
                lastCaptured: view?.lastCapturedUtc !== undefined ? new Date(view?.lastCapturedUtc) : undefined,
            };
        });

        //search
        if (search) {
            views = views.filter((x) => x.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()));
        }
        // Sorting
        if (sortBy) {
            const desc = sortBy.startsWith('-');
            const sortKey = sortBy.startsWith('-') ? sortBy.slice(1) : sortBy;

            views.sort((a, b) => {
                const aValue = (a as any)[sortKey];
                const bValue = (b as any)[sortKey];
                if (aValue === undefined || bValue === undefined) {
                    if (aValue === undefined && bValue === undefined) {
                        return 0;
                    } else if (aValue === undefined) {
                        return -1;
                    }
                    return 1;
                }
                if (aValue instanceof Date && bValue instanceof Date) {
                    return aValue.getTime() - bValue.getTime();
                }
                return aValue.localeCompare(bValue);
            });
            if (desc) views.reverse();
        }

        // Apply pagination locally
        const startIndex = (pageNumber - 1) * pageSize;
        const endIndex = Math.min(startIndex + pageSize, views.length);
        const paginatedViews = views.slice(startIndex, endIndex);

        viewCollectionRef.value.total_count = res.data.length;
        viewCollectionRef.value.pageSize = paginationOptions.value.pageSize;
        viewCollectionRef.value.pageNumber = paginationOptions.value.pageNumber;
        viewCollectionRef.value.offset = paginationOptions.value.pageSize * (paginationOptions.value.pageNumber - 1);
        viewCollectionRef.value.data = paginatedViews;
        return viewCollectionRef.value;
    }

    async function obtainViewListServerPagination(search?: string) {
        const pageNumber = paginationOptions.value.enabled ? paginationOptions.value.pageNumber : 1;
        const pageSize = paginationOptions.value.enabled ? paginationOptions.value.pageSize : Number.MAX_SAFE_INTEGER;
        const res = await client.listViews({
            organisation: applicationStore.activeOrganisation!.id,
            page: pageNumber,
            limit: pageSize,
        });
        let views = res.data;
        if (search !== undefined) views = views.filter((x) => x.name.toLowerCase().includes(search.toLowerCase()));
        viewCollectionRef.value.total_count = res.total_count;
        viewCollectionRef.value.pageSize = paginationOptions.value.pageSize;
        if (paginationOptions.value.pageNumber) viewCollectionRef.value.pageNumber = paginationOptions.value.pageNumber - 1;
        if (paginationOptions.value.pageSize && paginationOptions.value.pageNumber)
            viewCollectionRef.value.offset = paginationOptions.value.pageSize * (paginationOptions.value.pageNumber - 1);

        viewCollectionRef.value.data = views.map((view) => {
            return {
                ...view,
                lastSeen: view?.lastSeenUtc !== undefined ? new Date(view?.lastSeenUtc) : undefined,
            };
        });
        return viewCollectionRef.value;
    }

    async function obtainViewList(search?: string, sortBy?: string) {
        if (sortBy) return await obtainViewListLocalPagination(search, sortBy);
        return await obtainViewListServerPagination(search);
    }

    function enablePagination(value = true) {
        paginationOptions.value.enabled = value;
    }

    function setPagination(page: number, pageSize: number) {
        paginationOptions.value.pageNumber = page;
        paginationOptions.value.pageSize = pageSize;
    }

    // Get view by id
    async function getViewById(viewId: string) {
        const view = await client.getViewById({ viewId });
        return {
            ...view,
            lastSeen: view.lastSeenUtc !== undefined ? new Date(view.lastSeenUtc) : undefined,
        };
    }

    async function updateView(viewId: string, requestBody: client.UpdateViewRequest) {
        try {
            const res = await client.updateViewById({ viewId, requestBody });
            return { data: res };
        } catch (error) {
            console.error(error);
        }

        return { error: 'Unexpected error occurred, try again later.' } as const;
    }

    async function updateViewSource(viewId: string, requestBody: client.SetViewSourceRequest) {
        try {
            const res = await client.updateViewByIdSource({ viewId, requestBody });
            return { data: res };
        } catch (error) {
            console.error(error);
        }
        return { error: 'Unexpected error occurred, try again later.' } as const;
    }

    async function createView(requestBody: client.CreateViewRequest) {
        try {
            const res = await client.createView({ requestBody });
            return { data: res };
        } catch (error) {
            console.error(error);
        }

        return { error: 'Unexpected error occurred, try again later.' } as const;
    }

    async function deleteView(viewId: string): Promise<boolean> {
        try {
            await client.deleteViewById({ viewId });
            return true;
        } catch (error) {
            console.error(error);
            return false;
        }
    }

    /**
    * Fetches the manually inputted lat and long from views/[ID]/information for use in Google Maps.
    */
    async function listViewLocations(): Promise<MapMarker[]> {
        const vms: MapMarker[] = [];
        viewCollectionRef.value.data.forEach((x) => {
            vms.push({
                coordinates: {
                    latitude: x.latitude ?? 0,
                    longitude: x.longitude ?? 0
                },
                id: x.id
            });
        });
        return vms;
    }

    /**
     * Fetches the locations of all view cameras, checking for manual locations first set at views/[ID]/information.
     * If not set or falsy, fall back to the live camera stats latest call.
     * @returns A list of map marker lat & long within viewCollectionRef with coordinates and corresponding camera IDs for use in Google Maps.
     */
    async function getCamerasLocation(): Promise<MapMarker[]> {
        const mapMarkers: MapMarker[] = [];
        let manualCoordinatesCount = 0;

        // Prepare promises for each view to fetch the latest camera stats or use manual location
        const fetchPromises = viewCollectionRef.value.data.map(async (view) => {
            const cameraId = view.camera;

            // Check if the manual latitude and longitude are available
            const manualLatitude = view.latitude;
            const manualLongitude = view.longitude;

            if (manualLatitude && manualLongitude) {
                // Increment manual coordinates count
                manualCoordinatesCount++;
                // Use manual coordinates if available
                mapMarkers.push({
                    coordinates: {
                        latitude: manualLatitude,
                        longitude: manualLongitude,
                    },
                    id: view.id,
                });
                console.info(`Camera ${cameraId} has manual coordinates set: Latitude ${manualLatitude}, Longitude ${manualLongitude}`);
            } else {
                try {
                    // Fetch the latest stats for the current cameraId if manual coordinates are not available or falsy
                    const cameraStats = await client.getCameraByIdStatLatest(<GetCameraByIdStatLatestData>{ cameraId });

                    // Check if cameraStats contains valid latitude and longitude
                    if (cameraStats && cameraStats.gnssLatitude != null && cameraStats.gnssLongitude != null) {
                        // Convert latitude and longitude to decimal for use in Google Maps
                        const latitude = cameraStats.gnssLatitude / 1000000;
                        const longitude = cameraStats.gnssLongitude / 1000000;

                        // Define Europe's bounding box
                        const isInEurope = (latitude >= 35 && latitude <= 71) && (longitude >= -25 && longitude <= 45);

                        if (!isInEurope) {
                            console.warn(`Camera ${cameraId} has coordinates outside of Europe: Latitude ${latitude}, Longitude ${longitude}`);
                        }

                        // Add the valid coordinates to the marker list
                        mapMarkers.push({
                            coordinates: {
                                latitude,
                                longitude,
                            },
                            id: view.id,
                        });
                    } else {
                        console.warn(`Missing or invalid coordinates for camera ${cameraId}`);
                    }
                } catch (error) {
                    console.error(`Failed to fetch latest stats for camera ${cameraId}`, error);
                }
            }
        });

        console.warn(`We have ${manualCoordinatesCount} cameras with manual coordinates set of a total of ${viewCollectionRef.value.data.length} cameras`);

        // Wait for all fetch requests to complete before returning
        await Promise.all(fetchPromises);

        return mapMarkers;
    }



    return {
        viewCollectionRef,
        viewSelected,
        viewSelectedForDeletion,
        paginationOptions,
        datePickerIsOpen,
        getCamerasLocation,
        obtainViewList,
        updateView,
        updateViewSource,
        createView,
        deleteView,
        getViewById,
        setPagination,
        enablePagination,
    };
});
